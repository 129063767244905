import React from "react";

export default function useIsBulk() {
  const getIsBulk = (data) => {
    if (
      data.packing_group == "III" ||
      (data.packing_group == "" &&
        (data.trc_transport_category.split(" ")[0] == "3" ||
          data.trc_transport_category.split(" ")[0] == "4"))
    ) {
      return true;
    } else {
      return false;
    }
  };

  return { getIsBulk };
}
