import React, { useState } from "react";
import { useSelector } from "react-redux";
import { homeTranslation } from "../../../translations/home";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllChemicals } from "../../../services/Chemicals";
import ListCard from "../../../components/ListCard";
import Loader from "../../../common/Loader";
import { toast } from "react-toastify";
import {
  required,
  toastNotification,
} from "../../../translations/Modeltranslation";

export default function SearchTab() {
  const [searchType, setSearchType] = useState("un_number");
  const [searchData, setSearchData] = useState({ searchValue: "0004" });
  const [chemicalsData, setChemicalsData] = useState([]);
  const [chemicalsLoading, setChemicalsLoading] = useState(false);
  const lang = useSelector((state) => state.account?.lang);
  const isAuthenticated = useSelector((state) => state.account.isAuthenticated);

  const {
    UN_NAME,
    UN_NUMBER,
    NAME_EN,
    Main_Result,
    Main_heading,
    Main_Search,
    Home_placeholder_name,
    Home_placeholder_number,
    LOGIN_TO_PROCEED,
  } = homeTranslation;
  const { Login_shipment } = toastNotification;
  const { error_required } = required;
  const mutation = useMutation({
    mutationFn: getAllChemicals,
    onSuccess: (res) => {
      console.log("Register Submiting Response", res);
      // Invalidate and refetch

      console.log("Res  ---- ", res);
      if (res?.response?.status == 401) {
        toast.error(LOGIN_TO_PROCEED[lang]);
      }
      if (res?.data?.success) {
        setChemicalsData(res?.data?.data);
        setChemicalsLoading(false);
      } else {
        setChemicalsLoading(false);
      }
    },
    onError: (err) => {
      console.log("error ", err);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleSearch = (data) => {
    // refetch();
    if (isAuthenticated) {
      setChemicalsLoading(true);
      mutation.mutate(
        searchType == "un_number"
          ? `un_number=${searchData}`
          : `${NAME_EN[lang]}=${searchData}`
      );
    } else {
      toast.info(`${Login_shipment[lang]}`);
    }
  };
  return (
    <div>
      {chemicalsLoading && <Loader />}
      <form onSubmit={handleSubmit(handleSearch)}>
        <div className=" bg-white border shadow rounded-full flex md:max-w-xl mx-auto mb-2 mt-4">
          <div class=" text-black flex border-r-2 pr-2">
            <select
              name="search"
              id="search"
              onChange={(e) => setSearchType(e?.target?.value)}
              className="bg-white rounded-s-full pl-4 w-36 focus:outline-none"
            >
              <option value={NAME_EN[lang]}>{UN_NAME[lang]} </option>
              <option value="un_number" selected>
                {UN_NUMBER[lang]}
              </option>
            </select>
          </div>

          <input
            type={searchType == "un_number" ? "number" : "text"}
            className="w-full focus:outline-none text-black px-2"
            placeholder={
              searchType == "un_number"
                ? `${Home_placeholder_number[lang]}`
                : `${Home_placeholder_name[lang]}`
            }
            {...register("searchValue", {
              Required: true,
              minLength: searchType == "un_number" ? 4 : 0,
              onChange: (e) => setSearchData(e?.target?.value),
            })}
          />
          <button
            type="submit"
            className=" bg-amber-500 py-2 px-3.5 rounded-full focus:border-0 h-11 w-12"
          >
            <i class="fa fa-search text-lg"></i>
          </button>
        </div>
        <div className="text-center">
          {errors?.searchValue?.type === "required" && (
            <p className="text-red-700 my-1">Required</p>
          )}
          {errors?.searchValue?.type === "minLength" && (
            <p className="text-red-700 my-1">Min Length should be 4</p>
          )}
        </div>
      </form>

      <div className="py-10 container mx-auto">
        <h1 className="font-bold text-5xl mb-8 text-center">
          {" "}
          {isAuthenticated ? `${Main_heading[lang]}` : `${Main_Search[lang]}`}
        </h1>
        <div className="max-w-4xl mx-auto ">
          <h1 className="font-bold text-3xl mb-8">
            {chemicalsData?.data?.length || 0} {Main_Result[lang]}
          </h1>

          {chemicalsData?.data?.map((chem) => {
            return <ListCard data={chem} />;
          })}
        </div>
      </div>
    </div>
  );
}
