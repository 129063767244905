import React from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Contact from "../pages/Contact";
import Footer from "./Footer";
import Navbar from "./Navbar";
import DownloadSection from "../components/DownloadSection";
import About from "../pages/About";
import Registers from "../pages/Home/Tabs/RegistersTab";
import Consignee from "../pages/Consignee";
import ViewRegisters from "../pages/Registers/ViewRegisters";
import EditRegisters from "../pages/Registers/EditRegisters";
import Home from "../pages/Home/Home";
import Loader from "../common/Loader";
import { useSelector } from "react-redux";
import PrivacyPolicy from "../pages/PrivacyPolicy";

export default function Routing() {
  const isLoading = useSelector((state) => state?.modalState?.isLoading);

  return (
    <div className=" bg-gray-100 ">
      <BrowserRouter>
        <Routes>
          <Route
            element={
              <div>
                {isLoading && <Loader />}
                <Navbar />
                <Outlet />
                <DownloadSection />
                <Footer />
              </div>
            }
          >
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacy_policy" element={<PrivacyPolicy />} />
            {/* <Route path="/registers" element={<Registers />} /> */}
            <Route path="/view_registers/:id" element={<ViewRegisters />} />
            <Route path="/edit_registers/:id" element={<EditRegisters />} />
            <Route path="/consignee" element={<Consignee />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
