import React, { useLayoutEffect, useState } from "react";
import ListConsignee from "../components/Consignee/ListConsignee";
import ConsigneeModal from "../components/Consignee/ConsigneeModal";
import { useQuery } from "@tanstack/react-query";
import { getConsigneeList } from "../services/Consignee";
import Loader from "../common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { addConsigneeAction, handleAllModals } from "../redux/modalVisibility";
import { consigneeTranslation } from "../translations/consignee";

const Consignee = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const refetchConsignee = useSelector(
    (state) => state.modalState.refetchConsignee
  );
  const handleConsignee = () => {
    dispatch(handleAllModals({ addConsigneeModal: { isVisible: true } }));
  };

  const {
    isLoading: consigneeLoading,
    error: consigneeError,
    data: consignee,
  } = useQuery({
    queryKey: ["consigneeListing", refetchConsignee],
    queryFn: getConsigneeList,
  });

  console.log("Consignee Data ", consignee);
  const consigneeData = consignee?.data?.data;

  const lang = useSelector((state) => state.account?.lang);
  const { Consignee_HEADING, Consignee_BUTTON, consignee_sub_heading } =
    consigneeTranslation;
  return (
    <React.Fragment>
      {consigneeLoading && <Loader />}
      <div className="py-10 container mx-auto">
        <ConsigneeModal />
        <div className="max-w-4xl mx-auto ">
          <div className="flex justify-between">
            <p>
              {`${Consignee_HEADING[lang]} ${consigneeData?.length || 0} ${
                consignee_sub_heading[lang]
              }`}
            </p>
            <button
              className=" bg-amber-500 py-2 px-3.5 focus:border-0 text-white"
              onClick={handleConsignee}
            >
              {Consignee_BUTTON[lang]}
            </button>
          </div>

          {consigneeData?.map((cons) => {
            return <ListConsignee data={cons} />;
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Consignee;
