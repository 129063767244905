import React, { useLayoutEffect, useState } from "react";
import HeroSection from "../components/HeroSection";
import { aboutTranslation } from "../translations/about";
import { useSelector } from "react-redux";
export default function About() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const lang = useSelector((state) => state.account?.lang);
  const { ABOUT_DESCRIPTION, ABOUT_US } =
    aboutTranslation;
  // const {
  //   isLoading: chemicalsLoading,
  //   error: chemicalsError,
  //   data: chemicals,
  //   refetch,
  // } = useQuery({
  //   queryKey: ["chemicalsListing"],
  //   queryFn: () =>
  //     getAllChemicals(
  //       searchType == "un_number"
  //         ? `un_number=${searchData}`
  //         : `${NAME_EN[lang]}=${searchData}`
  //     ),
  // });

  return (
    <React.Fragment>
      <HeroSection
        title= {ABOUT_US[lang]}
        description=""
      />

      <div className="py-16  text-center flex justify-center">
        <div className="md:max-w-screen-md">
          <p className="py-5 ">
            {ABOUT_DESCRIPTION[lang]}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
