export const menuTranslation = {
  HOME: {
    en: "Home",
    it: "Home",
  },
  CONSIGNEE: {
    en: "Consignee",
    it: "Tua Lista Destinatari",
  },
  ABOUT_US: {
    en: "About Us",
    it: "Chi Siamo",
  },
  CONTACT_US: {
    en: "Contact Us",
    it: "Contattaci",
  },
  Sign_btn:{
    en:"SignUp",
    it:"Iscrizione"
  }
};



