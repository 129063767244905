import React from "react";

export default function HeroSection({ title, description }) {
  return (
    <div className="bg-black text-white py-16 text-center flex justify-center">
      <div className=" md:max-w-screen-md">
        <h3 className="text-4xl font-bold pb-5 uppercase">{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}
