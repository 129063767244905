import React, { useState } from "react";
import ModalRegister from "../ModalRegister";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { clearAllRegisters } from "../../redux/localRegisters";
import { handleAllModals } from "../../redux/modalVisibility";
import { useDispatch, useSelector } from "react-redux";
import { createRegisterInvoice } from "../../services/Registers";
import { getConsigneeList } from "../../services/Consignee";
import { MiniLoader } from "../../common/Loader";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { afterProceedModalTrans } from "../../translations/Modeltranslation";
import {
  BoxIcon,
  CalculatorIcon,
  CalendarIcon,
  TruckIcon,
} from "../../assets/Icons/icons";
const AfterProceedModal = () => {
  const [postLoading, setPostLoading] = useState(false);
  const localRegisters = useSelector((state) => state.localRegisters);
  const shipmentType = useSelector((state) => state.account.shipmentType);
  const lang = useSelector((state) => state.account?.lang);

  const [subType, setSubType] = useState(null);
  const navigate = useNavigate();
  const {
    isLoading: consigneeLoading,
    error: consigneeError,
    data: consignee,
  } = useQuery({
    queryKey: ["consigneeListing"],
    queryFn: getConsigneeList,
  });

  console.log("Consignee Data ", consignee);
  const consigneeData = consignee?.data?.data;

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: createRegisterInvoice,
    onSuccess: (res) => {
      console.log("Register Submiting Response", res);
      // Invalidate and refetch
      if (res?.data?.success) {
        dispatch(clearAllRegisters());
        queryClient?.invalidateQueries({ queryKey: ["registersListing"] });
        // hideConsigneeModal(true);
        // toast.error("Please Login to Proceed");
        // reset();
        setPostLoading(false);
        toast.success("Added to Registers Successful");
        dispatch(
          handleAllModals({
            afterProceedModal: { isVisible: false },
          })
        );
      } else {
        // queryClient.invalidateQueries({
        //   queryKey: ["consigneeListing"],
        // });
        // toast.success(res.data.message);
        setPostLoading(false);
      }
    },
    onError: (err) => {},
  });

  const onSubmit = (data) => {
    console.log("Data", data);
    setPostLoading(true);
    const today = new Date(); // this will get the current date and time
    const formattedDate = `${today.getFullYear()}-${String(
      today.getMonth() + 1
    ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
    let postData = { shipment_type: shipmentType, subType: subType, ...data };
    postData.items = localRegisters.map((d) => {
      return {
        chemical_id: d.id,
        point: d.points,
        quantity: d.qty,
        packaging_id: d?.packaging_id,
      };
    });
    postData.total_points = localRegisters?.reduce(
      (total, num) => total + num.points,
      0
    );

    postData.invoice_date = formattedDate;

    mutation.mutate(postData);
  };
  const hideModal = () => {
    dispatch(
      handleAllModals({
        afterProceedModal: { isVisible: false },
      })
    );
  };
  const handleNavigate = () => {
    navigate("/consignee");
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    SELECT_SHIPPING_RECIPIENT,
    SELECT_RECIPIENT,
    CLICK_TO_ADD_NEW_RECIPIENT,
    CONFIRM_TO_SAVE,
    SHIPMENT_TYPE,
    DATE,
    TOTAL_POINTS,
    TOTAL_UN,
    CANCEL,
  } = afterProceedModalTrans;
  return (
    <React.Fragment>
      <ModalRegister>
        <form onSubmit={handleSubmit(onSubmit)}>
          <label
            htmlFor="small"
            className="block mb-2 text-sm font-medium text-gray-900  "
          >
            {SELECT_SHIPPING_RECIPIENT[lang]}
          </label>
          <span className="flex bg-amber-500  rounded-lg">
            <select
              id="small"
              name="consignee_id"
              className="block w-full p-3 bg-amber-500 outline-none rounded-lg  text-sm text-gray-900 "
              {...register("consignee_id", {
                required: "required",
              })}
            >
              <option selected value="" disabled>
                {SELECT_RECIPIENT[lang]}
              </option>
              {consigneeData?.map((c, i) => {
                return (
                  <option key={i} value={c?.id}>
                    {" "}
                    {c?.name}{" "}
                  </option>
                );
              })}
            </select>
          </span>
          {errors.consignee_id && (
            <p className="text-red-800 mb-1">{errors.consignee_id.message}</p>
          )}
          <div className="text-center">
            <button
              onClick={handleNavigate}
              className="mb-2 text-sm   text-amber-500 p-7 font-medium"
            >
              {CLICK_TO_ADD_NEW_RECIPIENT[lang]}
            </button>
          </div>
          <div className="grid grid-cols-6 gap-4 border-b-2 items-center">
            <TruckIcon fill="lightgray" />

            <div className="col-start-2 col-span-4  m-4">
              <p className="text-gray-400"> {SHIPMENT_TYPE[lang]} </p>

              {shipmentType != "1136" ? (
                <span className="flex mt-2">
                  <div
                    onClick={() => setSubType("Bulk")}
                    className={`${
                      subType == "Bulk" && "bg-amber-500"
                    }  border border-gray-300 rounded-md font-bold w-2/4 p-1 mr-1 cursor-pointer text-center`}
                  >
                    Bulk
                  </div>
                  <div
                    onClick={() => setSubType("Tank")}
                    className={`${
                      subType == "Tank" && "bg-amber-500"
                    }  border border-gray-300 rounded-md font-bold w-2/4 p-1 mr-1 cursor-pointer text-center`}
                  >
                    Tank
                  </div>
                </span>
              ) : (
                <span className="flex mt-2">ADR 1.1.3.6.</span>
              )}
            </div>
          </div>

          <div className="grid grid-cols-6 gap-4 border-b-2 items-center">
            <CalendarIcon fill="lightgray" />
            <span className="  m-4 col-start-2 col-span-4">
              <p className="text-gray-400"> {DATE[lang]} </p>
              <p className="font-bold"> 28 Oct 2023</p>
            </span>
          </div>
          <div className="grid grid-cols-6 gap-4 border-b-2 items-center">
            <CalculatorIcon fill="lightgray" />

            <span className="  m-4 col-start-2 col-span-4">
              <p className="text-gray-400"> {TOTAL_POINTS[lang]} </p>
              <p className="font-bold">
                {" "}
                {localRegisters?.reduce((x, y) => y.points + x, 0)}
              </p>
            </span>
          </div>
          <div className="grid grid-cols-6 gap-4 border-b-2 items-center">
            <BoxIcon fill="lightgray" />

            <span className="  m-4 col-start-2 col-span-4">
              <p className="text-gray-400"> {TOTAL_POINTS[lang]} </p>
              <p className="font-bold"> {localRegisters?.length}</p>
            </span>
          </div>
          <div className="flex justify-around pt-6">
            <button onClick={hideModal} className="border border-gray-200 px-3 rounded-lg shadow"> {CANCEL[lang]} </button>
            <button
              // onClick={handleSubmit}
              type="submit"
              className="bg-amber-500 px-10  py-2 rounded-md text-white"
            >
              {postLoading ? <MiniLoader /> : CONFIRM_TO_SAVE[lang]}
            </button>
          </div>
        </form>
      </ModalRegister>
    </React.Fragment>
  );
};

export default AfterProceedModal;
