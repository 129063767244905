export const consigneeTranslation = {
  Consignee_HEADING: {
    en: "You have added ",
    it: "Hai aggiunto ",
  },
  consignee_sub_heading: {
    en: "Consignee so for",
    it: " Destinatario così per",
  },
  Consignee_BUTTON: {
    en: "Add new Consignee",
    it: "Aggiungi nuovo destinatario",
  },
  CONSIGNEE_Edit: {
    en: "Edit Consignee",
    it: "Modifica destinatario",
  },
  consign_submit_Model: {
    en: "submit",
    it: "invia",
  },
};
