import React, { useState } from "react";
import playIcon from "../../assets/Icons/playIcon.png";
import Header from "./headerome";
import { homeTranslation } from "../../translations/home";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const UpperHeader = () => {
  const lang = useSelector((state) => state.account?.lang);
  const {
    upper_lineApp,
    upper_lineApp_avilable,
    upper_lineApp_avilable_2,
    upper_lineApp_li1,
    upper_lineApp_li2,
    upper_lineApp_li3,
    upper_lineApp_li4,
  } = homeTranslation;
  return (
    <React.Fragment>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-3 bg-amber-500 p-11">
        <div className="lg:col-span-1 md:col-span-2 pb-11">
          <h3 className="font-bold leading-tight text-xl">
            {upper_lineApp[lang]}
          </h3>
          <ol className="list-decimal">
            <li>{upper_lineApp_li1[lang]}</li>
            <li>{upper_lineApp_li2[lang]}</li>
            <li>{upper_lineApp_li3[lang]}</li>
            <li>{upper_lineApp_li4[lang]}</li>
          </ol>
        </div>
        {/* center portion */}
        <div className="pb-11">
          <h3 className="text-center font-bold leading-tight text-xl">
            {upper_lineApp_avilable[lang]}
          </h3>
          <h3 className="text-center font-bold leading-tight text-xl">
            {upper_lineApp_avilable_2[lang]}
          </h3>
          <div className="flex py-4 justify-center ">
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="https://apps.apple.com/us/app/adr-cargo-school-dang-goods/id6469696566"
              className="  mr-1  bg-black  flex p-3 rounded items-center"
            >
              <div className="pr-3 pl-1">
                <i className="fa fa-apple text-2xl text-white" />
              </div>
              <div className="text-left font-medium">
                <p className="text-xs leading-tight text-white">
                  Download on the
                </p>
                <p className="text-lg leading-tight text-white">App Store</p>
              </div>
            </Link>

            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="https://play.google.com/store/apps/details?id=com.adrapp.cargoschool"
              className="    bg-black  flex p-3 rounded items-center"
            >
              <div className="pr-3 pl-1">
                <img src={playIcon} width="30" height="30" />
              </div>
              <div className="text-left font-medium">
                <p className="text-xs leading-tight text-white">
                  Download on the
                </p>
                <p className="text-lg leading-tight text-white">Google Play</p>
              </div>
            </Link>
          </div>
        </div>
        {/* portion for video */}
        <div className=" ">
          <video
            controls
            width="100%"
            height="auto"
            src="https://www.corsiadrerifiuti.it/wp-content/uploads/2023/10/Ecommerce-Trailer-720p-231027.mp4"
          ></video>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UpperHeader;
