export const downloadTranslation = {
  DOWN_DESCRIPTION: {
    en: "And don't forget to check out our mobile app - it's a completely offline searchable ADR 2023 database with many advanced functions, available for both Android and iOS.",
    it: "E non dimenticare di dare un'occhiata alla nostra app mobile: è un database ADR 2023 completamente ricercabile offline con molte funzioni avanzate, disponibile sia per Android che per iOS.",
  },
  HEADING: {
    en: "Download Our App",
    it: "Scarica la nostra App",
  },
};
