export const privacyTranslation = {
  PRIVACY_DESCRIPTION: {
    en: "With our app you can calculate the 1000 per 1.1.3.6 very quickly, check whether your dangerous goods can be sent with tank/bulk exemption and keep the mandatory register for 5 years, as required by the decree, which can be consulted and downloaded in the App or on the Web.",
    it: "Con la nostra app fai molto velocemente il calcolo dei 1000 per 1.1.3.6, controlli se la tua merce pericolosa puo essere inviata in esenzione Cisterna/Rinfusa e tieni il registro abbligatorio per 5 anni, come previsto dal decreto, consultabile e scaricabile in App o sul web",
  },
  PRIVACY_POLICY: {
    en: "Privacy Policy",
    it: "Informativa sulla privacy",
  },
};
