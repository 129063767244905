import { createSlice } from "@reduxjs/toolkit";
import session from "../services/utils/session";

const initialState = 1;

export const selectedTab = createSlice({
  name: "selectedTab",
  initialState,
  reducers: {
    setSelectedTab: (state, action) => {
      return action?.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedTab } = selectedTab.actions;

export default selectedTab.reducer;
