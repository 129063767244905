import React from "react";

export default function Loader() {
  return (
    <div className="fixed w-full h-full bg-black text-center top-0 left-0 bottom-0 z-50 flex bg-opacity-20">
      <div className="m-auto">
        <span className="loader m-auto"></span>
      </div>
    </div>
  );
}

export function MiniLoader() {
  return (
    <div className=" w-full h-5  text-center top-0 left-0  bottom-0 z-50 flex bg-opacity-20 items-center">
      <div className="mx-auto">
        <span className="loader m-auto"></span>
      </div>
    </div>
  );
}
