import React, { useState } from "react";
import ModalRegister from "../ModalRegister";
import boxLogo from "../../assets/images/delete.png";
import { useDispatch, useSelector } from "react-redux";
import { handleAllModals } from "../../redux/modalVisibility";

import { clearAllRegisters } from "../../redux/localRegisters";
import { consignModelDelete } from "../../translations/Modeltranslation";

const ConfirmDeleteAllCalc = ({}) => {
  const dispatch = useDispatch();

  const hideModal = () => {
    // setConsigneeModel(true);
    dispatch(
      handleAllModals({
        deleteAllCalcModal: { isVisible: false },
      })
    );
  };

  const handleClearAllCalc = () => {
    dispatch(clearAllRegisters());
    dispatch(handleAllModals({ deleteAllCalcModal: { isVisible: false } }));
  };
  const lang = useSelector((state) => state.account?.lang);
  const {
    shipment_subheading,
    shipment_subheading1,
    Consignee_delete_heading,
    consign_Model_Cancel,
    consign_Model_Proceed,
  } = consignModelDelete;

  return (
    <React.Fragment>
      <ModalRegister>
        <div className="flex justify-center">
          <span className="flex bg-white rounded-full w-12 h-11 z-10">
            <img
              src={boxLogo}
              width={100}
              className="text-center m-auto justify-center"
            />
          </span>
        </div>
        <div className="flex flex-col items-center pb-3">
          <h3 className="font-bold  py-2">{shipment_subheading[lang]}?</h3>
          <p className="text-center w-2/3">
            {shipment_subheading1[lang]} <b>{shipment_subheading[lang]}</b>
          </p>
        </div>
        <div className="flex justify-around pt-6">
          <button onClick={hideModal}>{consign_Model_Cancel[lang]}</button>
          <button
            onClick={handleClearAllCalc}
            className="bg-red-600 px-10  py-2 rounded-md text-white"
          >
            {consign_Model_Proceed[lang]}
          </button>
        </div>
      </ModalRegister>
    </React.Fragment>
  );
};

export default ConfirmDeleteAllCalc;
