import React, { useEffect, useState } from "react";
import boxLogo from "../../assets/images/box.png";
import bulkLogo from "../../assets/images/bulk.jpg";

import AddRegisterModal from "../AddRegisterModal";
import { useDispatch, useSelector } from "react-redux";
import { removeRegister, updateRegister } from "../../redux/localRegisters";
import AfterProceedModal from "../AfterProceedModal";
import { handleAllModals } from "../../redux/modalVisibility";
import { getPackagingList } from "../../services/Packaging";
import { useQuery } from "@tanstack/react-query";
import { shipmentCalTranslation } from "../../translations/about";

export default function LocalRegDetailCard({ data }) {
  const [modalVisible, setModalVisible] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const lang = useSelector((state) => state.account?.lang);

  const dispatch = useDispatch();
  const handleRegister = () => {
    setModalVisible(true);
  };
  const handleRemove = (id) => {
    dispatch(removeRegister(id));
  };

  const {
    isLoading: packagingLoading,
    error: packagingError,
    data: packaging,
  } = useQuery({
    queryKey: ["packagingListing"],
    queryFn: getPackagingList,
  });

  console.log("Consignee Data ", packaging);
  const packagingData = packaging?.data?.data;

  useEffect(() => {
    let tempPoints = data?.reduce((total, num) => total + num.points, 0);
    setTotalPoints(tempPoints);
  }, [data]);

  const handleUpdate = (value, id) => {
    dispatch(updateRegister({ value, id }));
  };

  const shipmentType = useSelector((state) => state.account.shipmentType);

  const afterProceedModalState = useSelector(
    (state) => state.modalState.afterProceedModal
  );

  const deleteAll = () => {
    dispatch(handleAllModals({ deleteAllCalcModal: { isVisible: true } }));
  };
  const {
    shipment_heading,
    shipment_subheading,
    shipment_allow,
    shipment_not_allow,
    shipment_Pg,
    shipment_Categoria,
    shipment_class,
    shipment_label,
    shipment_quantity,
    shipment_point,
    shipment_payment,
    shipment_list,
    shipment_register,
    CATEGORY_TRC,
    ADD_UN_TO,
  } = shipmentCalTranslation;
  return (
    <React.Fragment>
      {modalVisible && (
        <AddRegisterModal hideModal={() => setModalVisible(false)} />
      )}

      {afterProceedModalState?.isVisible && <AfterProceedModal />}
      <div class="bg-white border rounded-lg shadow-md">
        {/* <!-- Card Image --> */}
        <div class="w-full rounded-t-lg mb-4 bg-[#f89329] uppercase text-center font-bold text-xl py-3">
          {shipment_heading[lang]}
        </div>
        {/* <!-- Card Content --> */}
        <div className="p-4">
          {shipmentType == "1136" && (
            <div className="text-center">
              {data?.length > 0 && (
                <>
                  <p className="text-sm">{shipment_subheading[lang]}</p>
                  <h2 class="text-4xl my-1 text-green-600 font-black">
                    {" "}
                    {totalPoints}{" "}
                  </h2>
                  <p
                    className={`uppercase text-sm leading-tight font-black ${
                      totalPoints > 1000 ? "text-red-600" : "text-green-600"
                    } `}
                  >
                    {totalPoints > 1000
                      ? `${shipment_not_allow[lang]}`
                      : `${shipment_allow[lang]}`}
                  </p>
                </>
              )}
            </div>
          )}
          {data?.length == 0 && (
            <div className="text-center">
              <p className="text-lg leading-tight  font-black">
                {ADD_UN_TO[lang]}
                {/* Add UN(s) to See Calculation */}
              </p>
            </div>
          )}

          {data?.map((sub, i) => {
            // const {
            //   register,
            //   handleSubmit,
            //   setValue,
            //   reset,
            //   formState: { errors },
            // } = useForm();

            // setValue("qty", sub?.qty);
            return (
              <div className="border m-2 mt-5 p-4 rounded">
                <div className="flex justify-between mb-2">
                  <div className="flex items-center">
                    <span className="bg-green-600 h-8 w-8 rounded-full text-center text-white font-bold p-1 me-3">
                      {i + 1}
                    </span>
                    <span className="flex bg-white rounded-full w-12 h-11 z-1">
                      <img
                        src={shipmentType == "1136" ? boxLogo : bulkLogo}
                        width={40}
                        className="text-center m-auto justify-center"
                      />
                    </span>
                    <span className="bg-[#f89329] flex uppercase text-sm font-bold py-1 pl-7 pr-4 h-8 rounded-r-full -ml-5 my-auto">
                      <span className="my-auto"> {sub?.un_number} </span>
                    </span>
                  </div>

                  <button
                    className="flex"
                    onClick={() => handleRemove(sub?.un_number)}
                  >
                    <i className="fa fa-trash text-white w-8 text-center h-8 rounded-full bg-red-700  flex items-center justify-center" />
                  </button>
                </div>

                <p class="text-gray-600">{sub?.name_en}</p>

                <div className=" grid grid-cols-4 text-sm py-3">
                  <div className="border-r-1 text-start px-4 border-r">
                    <div className="font-bold">{CATEGORY_TRC[lang]}</div>
                    <div className="text-xs text-gray-700">
                      {sub?.trc_transport_category}
                    </div>
                  </div>
                  <div className="px-4 border-r text-start">
                    <div className="font-bold">{shipment_class[lang]}</div>
                    <div className="text-xs text-gray-700">{sub?.class} </div>
                  </div>
                  <div className="border-r text-start px-4">
                    <div className="font-bold">{shipment_label[lang]}</div>
                    <div className="text-xs text-gray-700"> {sub?.label} </div>
                  </div>
                  <div className="text-start px-4">
                    <div className="font-bold">{shipment_Pg[lang]}</div>
                    <div className="text-xs text-gray-700">
                      {sub?.packing_group}
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-2 text-sm pt-3 pb-2 ">
                  <label className="font-bold col-start-1 col-span-1 py-3  px-4">
                    {shipment_quantity[lang]}:
                  </label>
                  <div className="col-start-2 col-span-4 flex">
                    <input
                      // value={sub?.qty}
                      type="number"
                      min={1}
                      defaultValue={sub?.qty}
                      onChange={(e) =>
                        handleUpdate(e?.target?.value, sub?.un_number)
                      }
                      // onChange={(e) =>
                      //   sanitizeInput(e.target.value, sub?.un_number)
                      // }
                      name="qty"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-48 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    />
                  </div>
                  {shipmentType == "1136" && sub?.multiplier != "unlimited" && (
                    <div className="col-start-6 col-span-1 text-center font-bold">
                      x {sub?.multiplier} = {sub?.qty * sub?.multiplier}{" "}
                      {shipment_point[lang]}
                    </div>
                  )}
                </div>

                {shipmentType == "1136" && (
                  <div className="flex items-center gap-2 text-sm pt-3 pb-2 ">
                    <label className="font-bold col-start-1 col-span-1 py-3  px-4">
                      {shipment_payment[lang]}:
                    </label>

                    <span className="bg-amber-500 px-4 py-2 rounded-full">
                      {lang == "en"
                        ? packagingData?.find((pd) => {
                            return pd?.id == sub?.packaging_id;
                          })?.name_en
                        : packagingData?.find((pd) => {
                            return pd?.id == sub?.packaging_id;
                          })?.name_it}
                    </span>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {/* <!-- Card Actions --> */}
        {data?.length > 0 && (
          <div>
            <button
              onClick={deleteAll}
              className="bg-red-700 text-white rounded-bl-lg w-[50%] py-4"
            >
              <i className="fa fa-trash me-2"></i>
              <span className="text-sm">{shipment_list[lang]}</span>
            </button>
            <button
              className="rounded-ee-lg text-white bg-green-600 w-[50%] py-4"
              onClick={handleRegister}
            >
              <i className="fa fa-check-circle-o me-2"></i>
              <span className="text-sm">{shipment_register[lang]}</span>
            </button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
