import React from "react";
import ModalContainer from "../ModalContainer";
import stopwatchIcon from "../../assets/Icons/stopwatch.png";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { handleAllModals } from "../../redux/modalVisibility";
export default function TrialModal({ days }) {
  const dispatch = useDispatch();
  const hideModal = () => {
    dispatch(
      handleAllModals({
        expireTrialModal: { isVisible: false, days: 0 },
      })
    );
  };
  return (
    <ModalContainer hideModal={hideModal}>
      <img
        src={stopwatchIcon}
        width={80}
        className="mx-auto"
        alt="ADR Stop Watch"
      />
      <div className="text-center">
        <h1 className=" text-3xl font-bold pt-2 pb-1"> {days} </h1>
        <p className="pb-2">Giorni Restanti</p>
        <p>
          Hai {days} giorni restanti per il periodo di prova. Visita
          corsiadrerifuti.com per tutto il pacchetto di abbonamento annuale e
          molto altro ancora
        </p>
      </div>
      <div className="text-center pt-4">
        <Link
          to="www.corsiadrerifuti.com"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-amber-500 px-5 py-3 rounded-lg max-w-xs w-full "
        >
          {" "}
          Acquista Ora
        </Link>
      </div>
    </ModalContainer>
  );
}
