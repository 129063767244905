import React from "react"; 
import DetailCard from "../../../components/LocalRegisters/LocalRegDetailCard"
import { useSelector } from "react-redux";
import ConfirmDeleteAllCalc from "../../../components/LocalRegisters/ConirmDeleteAllCalc";

export default function CalculationTab() {
  const localRegisters = useSelector((state) => state.localRegisters);
  const deleteAllCalcModalState = useSelector(
    (state) => state.modalState.deleteAllCalcModal
  );

  return (
    <div>
      {deleteAllCalcModalState?.isVisible && <ConfirmDeleteAllCalc />}
      <div className="py-10 container mx-auto">
        <div class="max-w-4xl mx-auto">
          {/* Detail Card  */}
          <DetailCard data={localRegisters} />
        </div>
      </div>
    </div>
  );
}
