import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addConsigneeModal: {
    isVisible: false,
  },
  editConsigneeModal: {
    isVisible: false,
    data: null,
  },

  deleteConsigneeModal: {
    isVisible: false,
    id: null,
  },
  addSubstanceModal: {
    isVisible: false,
    data: null,
  },
  loginModal: {
    isVisible: false,
  },
  refetchConsignee: null,
  refetchShipment: null,
  isLoading: false,
};

export const modalReducer = createSlice({
  name: "modalVisibility",
  initialState,
  reducers: {
    handleAllModals: (state, action) => {
      return { ...state, ...action?.payload };
    },
    hideAllModals: (state, action) => {
      return {};
    },
    deleteConsigneeModal: (state, action) => {
      console.log(action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addConsigneeAction,
  editConsigneeAction,
  updateConsigneeAction,
  handleAllModals,
  addProcessModal,
  hideAllModals,
} = modalReducer.actions;

export default modalReducer.reducer;
