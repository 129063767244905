import { createSlice } from "@reduxjs/toolkit";
import session from "../services/utils/session";

const initialState = session.get("localRegisters") || [];

export const registersReducer = createSlice({
  name: "registers",
  initialState,
  reducers: {
    addLocalRegisters: (state, action) => {
      let tempState = state;
      tempState.push(action?.payload);
      session.set("localRegisters", tempState);
      return tempState;
    },
    removeRegister: (state, action) => {
      let tempState = state?.filter((d) => d?.un_number != action.payload);
      session.set("localRegisters", tempState);
      return tempState;
    },
    updateRegister: (state, action) => {
      let tempState = state?.map((d) => {
        if (d?.un_number == action.payload?.id) {
          return {
            ...d,
            qty: action?.payload.value,
            points: action?.payload.value * d?.multiplier,
          };
        } else {
          return d;
        }
      });
      session.set("localRegisters", tempState);
      return tempState;
    },

    clearAllRegisters: (state, action) => {
      session.set("localRegisters", []);
      return [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { addLocalRegisters, removeRegister, clearAllRegisters , updateRegister} =
  registersReducer.actions;

export default registersReducer.reducer;
