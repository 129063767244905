import { configureStore } from "@reduxjs/toolkit";

import accountReducer from "./account";
import modalReducer from "./modalVisibility";
import registersReducer from "./localRegisters";
import selectedTab from "./selectedTab";
import bookmarkReducer from "./bookmarks";
export default configureStore({
  reducer: {
    account: accountReducer,
    modalState: modalReducer,
    localRegisters: registersReducer,
    selectedTab: selectedTab,
    bookmarks: bookmarkReducer,
  },
});
