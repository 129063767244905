import React from "react";
import ModalContainer from "../ModalContainer";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { signup } from "../../services/Account";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";
import { handleAllModals } from "../../redux/modalVisibility";
import {
  signModelTranslation,
  toastNotification,
} from "../../translations/Modeltranslation";

export default function SignModal({}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.account?.lang);
  const { REGISTER_TOAST } = toastNotification;

  const hideModal = (email) => {
    dispatch(
      handleAllModals({
        signupModal: { isVisible: false },
        verifyOTPModal: { isVisible: true, data: email },
      })
    );
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    const { password, confirm_password, email } = data;
    let postData = {
      email,
      password,
      confirm_password,
      name: data.fname + " " + data?.lname,
    };
    setLoading(true);
    console.log("Login Data ", postData);
    signup(postData)
      .then((res) => {
        console.log("Log in Res", res);
        if (res?.data?.success) {
          hideModal(email);
          setLoading(false);
          toast.success(REGISTER_TOAST[lang]);
        }
      })
      .catch((err) => {
        console.log("Login Err ", err);
      });
  };
  const {
    Signup_heading,
    Confirmpassword,
    Email,
    Last,
    Name,
    Password,
    required,
    email_required,
    register_btn,
  } = signModelTranslation;
  return (
    <React.Fragment>
      <ModalContainer hideModal={hideModal} title={Signup_heading[lang]}>
        {loading && <Loader />}
        <form onSubmit={handleSubmit(onSubmit)} class="space-y-6" action="#">
          <div className="flex justify-around">
            <div>
              <label
                for="fname"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                {Name[lang]}
              </label>
              <input
                type="text"
                name="fname"
                id="fname"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-48 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder={Name[lang]}
                {...register("fname", {
                  required: `${required[lang]}`,
                })}
              />
              {errors.fname && (
                <p className="text-red-800 mb-1">{errors.fname.message}</p>
              )}
            </div>
            <div>
              <label
                for="lname"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                {Last[lang]}
              </label>
              <input
                type="text"
                name="lname"
                id="lname"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-48 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                placeholder={Last[lang]}
                {...register("lname", {
                  required: `${required[lang]}`,
                })}
              />
              {errors.lname && (
                <p className="text-red-800 mb-1">{errors.lname.message}</p>
              )}
            </div>
          </div>

          <div>
            <label
              for="email"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {Email[lang]}
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              placeholder="name@company.com"
              {...register("email", {
                required: `${email_required[lang]}`,
                pattern:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />
          </div>
          {errors.email && (
            <p className="text-red-800 mb-1">{errors.email.message}</p>
          )}
          <div>
            <label
              for="password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {Password[lang]}
            </label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="••••••••"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              {...register("password", {
                required: true,
                minLength: 6,
              })}
            />
          </div>
          <div>
            <label
              for="password"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {Confirmpassword[lang]}
            </label>
            <input
              type="password"
              name=" confirm_password"
              id="confirm-password"
              placeholder="••••••••"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              {...register("confirm_password", {
                required: true,
                minLength: 6,
              })}
            />
          </div>

          <button
            // type="submit"
            // onClick={openLoginModal}
            className="w-full text-white bg-[#f89329] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800  uppercase"
          >
            {register_btn[lang]}
          </button>
        </form>
      </ModalContainer>
    </React.Fragment>
  );
}
