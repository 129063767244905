import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { menuTranslation } from "../../translations/menu";
import { Link } from "react-router-dom";
import ThemeSwitcher from "../switch/ThemeSwitcher";
import logo from "../../assets/images/logo.png";
import logo2 from "../../assets/images/iata-iso-widget.png";
import logo3 from "../../assets/images/ENAC-ADD_logo.png";
const ToggleBtn = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const lang = useSelector((state) => state.account?.lang);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const user = useSelector((state) => state.account.user);
  const isAuthenticated = useSelector((state) => state.account.isAuthenticated);
  const { HOME, CONSIGNEE, ABOUT_US, CONTACT_US, Sign_btn } = menuTranslation;
  return (
    <React.Fragment>
      <div className="flex">
        <button
          onClick={toggleSidebar}
          data-drawer-target="default-sidebar"
          data-drawer-toggle="default-sidebar"
          aria-controls="default-sidebar"
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
        {
          <>
            {isSidebarOpen && (
              <div
                onClick={toggleSidebar}
                className="fixed w-full h-full bg-black top-0 bottom-0 left-0 right-0 overflow-hidden z-10 opacity-50"
              ></div>
            )}
            <div
              id="default-sidebar"
              className={`fixed top-0 left-0 z-40 w-40 h-screen transition-transform ${
                isSidebarOpen ? "translate-x-0" : "-translate-x-full"
              } bg-amber-500 dark:bg-secondary`}
              aria-label="Sidebar"
            >
              <div className="text-2xl font-bold flex items-center justify-around  my-4">
                <Link to="/">
                  <img src={logo} width="90" />
                </Link>
                {/* <Link to="/" className="px-2 flex">
                <img
                  src={logo3}
                  width="60"
                  // height={30}
                  className="items-center py-2"
                />
              </Link>
              <Link to="/" className="px-2 items-center">
                <img src={logo2} width="60" />
              </Link> */}
                <span className="cursor-pointer" onClick={toggleSidebar}>
                  <i class="fa fa-times text-xs" aria-hidden="true"></i>
                </span>
              </div>

              <ul className=" ">
                <li className="my-4 ms-2">
                  <Link
                    to="/"
                    className={`${
                      false ? "text-black  " : "hover:text-white"
                    } font-medium text-sm`}
                  >
                    {HOME[lang]}
                  </Link>
                </li>

                <li className="my-4 ms-2">
                  <Link
                    to="/about"
                    className={`${
                      false ? "text-black " : "hover:hover:text-white"
                    } font-medium text-sm`}
                  >
                    {ABOUT_US[lang]}
                  </Link>
                </li>

                <li className="my-4 ms-2">
                  <Link
                    to="/consignee"
                    className={`${
                      false ? "text-black " : "hover:hover:text-white"
                    } font-medium text-sm`}
                  >
                    {CONSIGNEE[lang]}
                  </Link>
                </li>

                <li className="my-4 ms-2">
                  <Link
                    to="/contact"
                    className={`${
                      false ? "text-black" : "hover:hover:text-white"
                    } font-medium text-sm`}
                  >
                    {CONTACT_US[lang]}
                  </Link>
                </li>

                <li className="my-4 ms-2">
                  <ThemeSwitcher />
                </li>
              </ul>
            </div>{" "}
          </>
        }
      </div>
    </React.Fragment>
  );
};

export default ToggleBtn;
