import React, { useState } from "react";
import logo from "../../assets/images/consignee.png";
import DeleteConsignee from "./DeleteConsignee";
import EditConsignee from "./EditConsignee";
import { handleAllModals } from "../../redux/modalVisibility";
import { useDispatch, useSelector } from "react-redux";
import { consignModel } from "../../translations/Modeltranslation";
const ListConsignee = ({ data }) => {
  const dispatch = useDispatch();
  const handleConsigneeDelete = () => {
    dispatch(
      handleAllModals({
        deleteConsigneeModal: { isVisible: true, id: data?.id },
      })
    );
  };
  const handleConsigneeEdit = () => {
    dispatch(
      handleAllModals({ editConsigneeModal: { isVisible: true, data: data } })
    );
  };
  const delConsigneeModalState = useSelector(
    (state) => state.modalState.deleteConsigneeModal
  );
  const lang = useSelector((state) => state.account?.lang);
  const {
    consign_Address_Model,
    consign_Name_Model,
    consign_phone_Model,
    Consignee_Create,
  } = consignModel;
  // const date = new Date();

  return (
    <React.Fragment>
      <div className="border rounded-2xl mx-auto  my-5 bg-white">
        <EditConsignee />
        {delConsigneeModalState?.isVisible && <DeleteConsignee />}
        <div className="grid md:grid-cols-5 grid-cols-1 text-sm p-5">
          <span className="flex flex-col bg-amber-500 bg-opacity-50 rounded-full h-40 w-40 z-1 p-5">
            <img
              src={logo}
              // width={200}
              className="text-center justify-center object-contain"
            />
          </span>
          <div className="col-span-4">
            <div className="flex justify-between h-full">
              <div className=" flex items-center text-sm ml-5">
                <div>
                  <div className="border-r-1 text-start px-4 pb-3">
                    {/* <div className="font-bold text-gray-400">
                    {consign_Name_Model[lang]}
                  </div> */}
                    <div className="  text-gray-700 font-bold text-lg">
                      {data?.name}
                    </div>
                  </div>
                  <div className="border-r-1 text-start px-4 pb-3">
                    {/* <div className="font-bold text-gray-400">
                    {consign_Address_Model[lang]}
                  </div> */}
                    <div className="  text-gray-700  text-lg">
                      <i
                        class="fa fa-map-marker text-amber-500 me-2 font-bold text-lg"
                        aria-hidden="true"
                      ></i>
                      {data?.address}
                    </div>
                  </div>
                  <div className="border-r-1 text-start px-4 pb-3">
                    <div className="text-lg text-gray-700 ">
                      {/* <i
                        class="fa fa-phone text-amber-500 me-2 font-bold text-lg"
                        aria-hidden="true"
                      ></i> */}
                      {data?.city_postal_code}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-between">
                <div className="border-r-1 text-end px-4 pb-3">
                  <div className="text-lg text-gray-700 font-bold">
                    <p className=" font-bold text-sm text-gray-400">
                      {Consignee_Create[lang]}
                    </p>
                    <p>
                      {new Date().getDate()}{" "}
                      {new Date().toLocaleDateString("en-En", {
                        year: "numeric",
                        month: "long",
                      })}
                    </p>
                  </div>
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={handleConsigneeDelete}
                    className="flex items-center text-center   bg-red-300 w-10 h-10   text-red-600 rounded-full m-1 "
                  >
                    <i className="fa fa-trash mx-auto text-xl"></i>
                  </button>
                  <button
                    onClick={handleConsigneeEdit}
                    className="flex items-center bg-indigo-300 w-10 h-10 text-indigo-600 rounded-full m-1"
                  >
                    <i className="fa fa-pencil mx-auto text-xl"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ListConsignee;
