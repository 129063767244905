import React, { useState } from "react";
import boxLogo from "../assets/images/box-warning-icon.png";
import bulkWarning from "../assets/images/bulk warning.png";
import ModalRegister from "./ModalRegister";
import { checkLimit, createRegisterInvoice } from "../services/Registers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { clearAllRegisters } from "../redux/localRegisters";
import Loader, { MiniLoader } from "../common/Loader";
import { useEffect } from "react";
import { handleAllModals } from "../redux/modalVisibility";
import { shipmentModal } from "../translations/Modeltranslation";
import moment from "moment";
const AddRegisterModal = ({ hideModal }) => {
  const [loading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [limitData, setLimitData] = useState({});
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const shipmentType = useSelector((state) => state.account.shipmentType);

  const localRegisters = useSelector((state) => state.localRegisters);

  const handleSubmit = () => {
    dispatch(
      handleAllModals({
        afterProceedModal: { isVisible: true },
      })
    );
    hideModal();
  };

  useEffect(() => {
    setGetLoading(true);
    checkLimit()
      .then((res) => {
        if (res?.data?.success) {
          setGetLoading(false);
          setLimitData(res?.data?.data);
          console.log("Limit Response ", res);
        }
      })
      .catch((err) => {
        console.log("Err ", err);
      });
  }, []);

  const lang = useSelector((state) => state.account?.lang);
  const {
    shipment_warning,
    shipment_text,
    shipment_subtext_you,
    shipment_textbold,
    shipment_outof,
    shipment_Model_Cancel,
    shipment_Model_Proceed,
  } = shipmentModal;
  return (
    <React.Fragment>
      <ModalRegister>
        {loading && <Loader />}
        <div className="flex justify-center">
          <span className="flex bg-white rounded-full w-12 h-11 z-10">
            <img
              src={shipmentType == 1136 ? boxLogo : bulkWarning}
              width={100}
              className="text-center m-auto justify-center"
            />
          </span>
        </div>
        <div className="flex flex-col items-center pb-3">
          <h3 className="font-bold  py-2">{shipment_warning[lang]}!</h3>
          <p className="text-center w-2/3">{shipment_text[lang]}</p>
        </div>
        <div className=" flex py-5 justify-center items-center bg-orange-100 border-2 border-orange-600 rounded-md">
          <ul className="text-center">
            {getLoading ? (
              <MiniLoader />
            ) : (
              <>
                <li className="font-bold">
                  {shipment_subtext_you[lang]}{" "}
                  {shipmentType == "1136" ? "1.1.3.6" : "Bulk / Tank"}{" "}
                  {/* {shipment_textbold[lang]} */}
                </li>
                <li>
                  <span className="font-bold"> {moment().format('MMMM')} Mese </span>{" "}
                  <span>
                    {shipmentType == "1136"
                      ? limitData["1136"]?.month
                      : limitData?.bulk?.month}{" "}
                    {shipment_outof[lang]} 3
                  </span>
                </li>
                <li>
                  <span className="font-bold">  {moment().format('YYYY')} Anno</span>{" "}
                  <span>
                    {" "}
                    {shipmentType == "1136"
                      ? limitData["1136"]?.year
                      : limitData?.bulk?.year}{" "}
                    {shipment_outof[lang]} 24
                  </span>
                </li>
              </>
            )}
          </ul>
        </div>
        <div className="flex justify-around pt-6 items-center">
          <button className="border border-gray-300 px-10 py-2 rounded-md " onClick={hideModal}>{shipment_Model_Cancel[lang]}</button>
          <button
            onClick={handleSubmit}
            className="bg-green-600 px-10  py-2 rounded-md text-white"
          >
            {getLoading || postLoading ? (
              <MiniLoader />
            ) : (
              `${shipment_Model_Proceed[lang]}`
            )}
          </button>
        </div>
      </ModalRegister>
    </React.Fragment>
  );
};

export default AddRegisterModal;
