import React, { useState } from "react";
import ModalContainer from "../ModalContainer";
import { useForm } from "react-hook-form";
import { login } from "../../services/Account";
import { useDispatch, useSelector } from "react-redux";
import { loginAction, logoutAction } from "../../redux/account";
import Loader, { MiniLoader } from "../../common/Loader";
import { toast } from "react-toastify";
import { handleAllModals } from "../../redux/modalVisibility";
import {
  signModelTranslation,
  toastNotification,
} from "../../translations/Modeltranslation";
import { updateAllBookmark } from "../../redux/bookmarks";
import moment from "moment";
import useSubscription from "../../hooks/useCheckSubscription";

export default function LoginModal({}) {
  const { checkSubscription } = useSubscription();
  const [loading, setLoading] = useState(false);
  const lang = useSelector((state) => state.account?.lang);
  const {
    Email,
    Password,
    email_required,
    lost_password,
    not_register,
    login_Account,
    create_account,
  } = signModelTranslation;
  const { login_toast, REGISTER_TOAST } = toastNotification;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const handleSignupModal = () => {
    dispatch(handleAllModals({ signupModal: { isVisible: true } }));
    hideModal();
  };

  const hideModal = () => {
    dispatch(
      handleAllModals({
        isLoading: false,
        loginModal: { isVisible: false },
        refetchShipment: Math.random(),
      })
    );
  };
  const onSubmit = (data) => {
    dispatch(
      handleAllModals({
        isLoading: true,
      })
    );
    login(data)
      .then((res) => {
        console.log("Log in Res", res);
        console.log(
          "Moment End Date ",
          moment.unix(res?.data?.data?.trial?.end_date).diff(moment(), "days")
        );
        if (res?.data?.success) {
          checkSubscription(res?.data?.data);
          let trialDays = moment
            .unix(res?.data?.data?.trial?.end_date)
            .diff(moment(), "days");

          dispatch(loginAction(res?.data?.data));
          dispatch(updateAllBookmark([]));
          // if (data?.data?.subscription == null) {
          //   if (trialDays + 1 > 0) {
          //     toast.success(`${login_toast[lang]}`);
          //     dispatch(
          //       handleAllModals({
          //         trialModal: { isVisible: true, days: trialDays + 1 },
          //       })
          //     );
          //   } else {
          //     dispatch(
          //       handleAllModals({
          //         expireModal: { isVisible: true, days: trialDays + 1 },
          //       })
          //     );
          //     dispatch(logoutAction());
          //   }
          // }

          hideModal();
        } else {
          res?.response?.data?.data?.email?.map((er) => {
            return toast?.error(er);
          });
          res?.response?.data?.data?.error &&
            toast?.error(res?.response?.data?.data?.error);

          dispatch(
            handleAllModals({
              isLoading: false,
            })
          );
        }
      })
      .catch((err) => {
        console.log("Login Err ", err);
      });
  };
  const openRegisterModal = () => {
    hideModal();
    handleSignupModal();
  };

  return (
    <ModalContainer hideModal={hideModal} title="Login">
      <form onSubmit={handleSubmit(onSubmit)} class="space-y-6">
        <div>
          <label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            {Email[lang]}
          </label>
          <input
            type="email"
            name="email"
            id="email"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            placeholder="name@company.com"
            {...register("email", {
              required: `${email_required[lang]}`,
              pattern:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
          />
        </div>
        {errors.email && (
          <p className="text-red-800 mb-1">{errors.email.message}</p>
        )}
        <div>
          <label
            for="password"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            {Password[lang]}
          </label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="••••••••"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            {...register("password", {
              required: true,
              minLength: 6,
            })}
          />
        </div>

        {errors?.password?.type === "required" && (
          <p className="text-red-800 mb-1">Required</p>
        )}
        {errors?.password?.type === "minLength" && (
          <p className="text-red-800 mb-1">Min Length should be 6</p>
        )}
        <div class="flex justify-between">
          <div class="flex items-start">
            {/* <div class="flex items-center h-5">
              <input
                id="remember"
                type="checkbox"
                value=""
                class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                required
              />
            </div>
            <label
              for="remember"
              class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Remember me
            </label> */}
          </div>
          <a
            href="#"
            class="text-sm text-blue-700 hover:underline dark:text-blue-500"
          >
            {lost_password[lang]}?
          </a>
        </div>
        <button
          type="submit"
          class="w-full text-white bg-[#f89329] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          {loading ? <MiniLoader /> : `${login_Account[lang]}`}
        </button>
        <div class="text-sm font-medium text-gray-500 dark:text-gray-300">
          {not_register[lang]}
          <button
            onClick={openRegisterModal}
            class="text-blue-700 ms-2 hover:underline dark:text-blue-500"
          >
            {create_account[lang]}
          </button>
        </div>
      </form>
    </ModalContainer>
  );
}
