import { POST } from "./Adapter/base";

export const login = (data) => {
  return POST(`auth/login`, data);
};
// export const forgotPassword = (data) => {
//   return POST(`forgot_password`, data);
// };
// export const updatePassword = (data) => {
//   return POST(`reset_password`, data);
// };

export const signup = (user) => {
  return POST(`auth/register`, user);
};
export const resendOTP = (data) => {
  return POST(`auth/resend_otp`, data);
};
export const verifyOTP = (data) => {
  return POST(`auth/verify_otp`, data);
};
export const accountVerify = (data) => {
  return POST(`auth/account_varify`, data);
};
