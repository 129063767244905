import React from "react";
import appIcon from "../assets/Icons/app.svg";
import contactIcon from "../assets/Icons/contact.svg";
import articleIcon from "../assets/Icons/article.svg";
import shopIcon from "../assets/Icons/shop.svg";
import { useSelector } from "react-redux";
import { footerTranslation } from "../translations/about";
import { privacyTranslation } from "../translations/privacy";
import { Link } from "react-router-dom";

export default function Footer() {
  const now = new Date();
  console.log("now", now);
  const lang = useSelector((state) => state.account?.lang);
  const {
    footer_article,
    footer_contact,
    footer_download,
    footer_shop,
    footer_term_condition,
    footer_reserved,
  } = footerTranslation;

  const { PRIVACY_POLICY } = privacyTranslation;
  return (
    <div className="bg-[#0e0f11]">
      <div className="container mx-auto md:px-16 py-12">
        <div className=" grid grid-cols-2 divide-x-1 sm:grid-cols-4 text-sm gap-4">
          <div className="border-r border-gray-700 text-center">
            <button>
              <span className="bg-[#f89329] rounded-full p-5 inline-block rotate-360">
                <img src={appIcon} width={40} height={40} />
              </span>
              <p className="text-white font-medium text-lg hover:text-[#f89329]">
                {footer_download[lang]}
              </p>
            </button>
          </div>

          <div className="border-r border-gray-700 text-center">
            <button>
              <span className="bg-[#f89329] rounded-full p-5 inline-block rotate-360">
                <img src={contactIcon} width={40} height={40} />
              </span>
              <p className="text-white font-medium text-lg hover:text-[#f89329]">
                {footer_contact[lang]}
              </p>
            </button>
          </div>

          <div className="border-r border-gray-700 text-center">
            <button>
              <span className="bg-[#f89329] rounded-full p-5 inline-block rotate-360">
                <img src={articleIcon} width={40} height={40} />
              </span>
              <p className="text-white font-medium text-lg hover:text-[#f89329]">
                {footer_article[lang]}
              </p>
            </button>
          </div>

          <div className="text-center">
            <button className="group">
              <span className="bg-[#f89329] rounded-full p-5 inline-block rotate-360">
                <img src={shopIcon} width={40} height={40} />
              </span>
              <p className="text-white font-medium text-lg hover:text-[#f89329]">
                {footer_shop[lang]}
              </p>
            </button>
          </div>
        </div>

        <hr className="my-9 border-gray-700" />

        <div className="grid md:grid-cols-3 grid-cols-2 text-center justify-center text-sm gap-4">
          <div className="border-r px-4 text-[#f89329] md:text-right text-center self-center">
            {footer_term_condition[lang]}
          </div>
          <Link
            to="/privacy_policy"
            className=" md:border-r px-4 text-[#f89329] md:text-right text-center  self-center"
          >
            {PRIVACY_POLICY[lang]}
          </Link>
          <div className="text-white col-span-2 md:col-span-1 pt-4 md:pt-0 self-center">
            © {new Date().getFullYear()}, {footer_reserved[lang]}
          </div>
        </div>
      </div>
    </div>
  );
}
