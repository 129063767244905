import React, { useState } from "react";
import ModalRegister from "../ModalRegister";
import boxLogo from "../../assets/images/delete.png";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteRegister } from "../../services/Registers";
import { useDispatch, useSelector } from "react-redux";
import { handleAllModals } from "../../redux/modalVisibility";
import { toast } from "react-toastify";
import { MiniLoader } from "../../common/Loader";
import { consignModelDelete } from "../../translations/Modeltranslation";
const ListRegisterDelete = ({ id }) => {
  const [postLoading, setPostLoading] = useState(false);
  const dispatch = useDispatch();
  const hideModal = (isRefetch) => {
    dispatch(
      handleAllModals({
        deleteShipmentModal: { isVisible: false, id: null },
        refetchShipment: isRefetch ? Math.random() : null,
      })
    );

    // setdeleteRegister(true);
  };
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteRegister,
    onSuccess: (res) => {
      console.log("Register Deleting Response", res);
      // Invalidate and refetch
      if (res?.data?.success) {
        queryClient.invalidateQueries({
          queryKey: ["registersListing"],
        });
        toast.success("Deleted Successfuly");
        hideModal(true);
        // hideConsigneeModal(true);
        // toast.error("Please Login to Proceed");
        // reset();

        setPostLoading(false);
      } else {
        // toast.success(res.data.message);
        setPostLoading(false);
      }
    },
    onError: (err) => {},
  });

  const handleDelete = () => {
    setPostLoading(true);
    mutation.mutate(id);
  }
  const lang = useSelector((state) => state.account?.lang);
  const {Consignee_delete_heading,Consignee_delete_text,consign_Model_Cancel,consign_Model_Proceed,register_delet} =
  consignModelDelete;
  return (
    <React.Fragment>
      <ModalRegister>
        <div className="flex justify-center">
          <span className="flex bg-white rounded-full w-12 h-11 z-10">
            <img
              src={boxLogo}
              width={100}
              className="text-center m-auto justify-center"
            />
          </span>
        </div>
        <div className="flex flex-col items-center pb-3">
          <h3 className="font-bold  py-2">{Consignee_delete_heading[lang]}?</h3>
          <p className="text-center w-2/3">{register_delet[lang]}</p>
        </div>

        <div className="flex justify-around pt-6">
          <button disabled={postLoading} onClick={hideModal}>
            {consign_Model_Cancel[lang]}
          </button>
          <button
            disabled={postLoading}
            onClick={handleDelete}
            className="bg-red-600 px-10  py-2 rounded-md text-white"
          >
            {postLoading ? <MiniLoader /> : `${consign_Model_Proceed[lang]}`}
          </button>
        </div>
      </ModalRegister>
    </React.Fragment>
  );
};

export default ListRegisterDelete;
