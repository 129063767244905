export const homeTranslation = {
  Main_heading: {
    en: "Search Above",
    it: "Cerca sopra",
  },
  Main_Result: {
    en: "Result Found",
    it: "Risultato trovato",
  },
  Main_Search: {
    en: "Please Login to Search Above",
    it: "Effettua il login per cercare sopra",
  },
  search_bar: {
    en: "Search UN",
    it: "Ricerca",
  },
  shipment: {
    en: "Shipment Calculation",
    it: "Calc. Spedizione",
  },
  register: {
    en: "Register",
    it: "Registro",
  },
  bookmark: {
    en: " Bookmark",
    it: "Preferiti",
  },
  btn_text: {
    en: " Bulk/Trank",
    it: "Rinfusa/Cisterna",
  },
  EXEMPTION: {
    en: "Exemption",
    it: "esenzione",
  },
  FIND_CHEMICAL: {
    en: "find chemical",
    it: "trova sostanza chimica",
  },
  HERO_TEXT: {
    en: "Use the Search box or the links at the top of the the page to find all the information you need about any substance in the ADR 2023 system for road carriage of dangerous goods",
    it: "Utilizza la casella di ricerca o i link nella parte superiore della pagina per trovare tutte le informazioni necessarie su qualsiasi sostanza presente nel sistema ADR 2023 per il trasporto stradale di merci pericolose",
  },
  UN_NAME: {
    en: "UN Name",
    it: "UN Nome",
  },
  UN_NUMBER: {
    en: "UN Number",
    it: "UN Numero",
  },
  NAME_EN: {
    en: "name_en",
    it: "name_it",
  },
  model_amount_heading: {
    en: "Enter Amount",
    it: "Inserisci Quantità",
  },
  model_amount_subheading: {
    en: "You can change it later in the shipping calculation",
    it: "Puoi modificarlo in seguito nel calcolo spedizione",
  },
  CHOOSE_PKG: {
    en: "Choose a packaging",
    it: "Seleziona Imballaggio",
  },
  model_amount_max: {
    en: "Max",
    it: "Massimo ",
  },
  model_amount_maxPoint: {
    en: "Point",
    it: "Punti",
  },
  model_amount_cancel: {
    en: "Cancel",
    it: "Annulla",
  },
  model_amount_proceed: {
    en: "Proceed",
    it: "Salva",
  },

  /**
   * Here upper head translate
   */
  upper_lineApp: {
    en: 'We have the "ADR Cargo School" app,',
    it: "Abbiamo l'app' ADR Cargo School",
  },
  upper_lineApp_avilable: {
    en: "You have a 14-day trial period,",
    it: "Hai un periodo di prova di 14 giorni,",
  },
  upper_lineApp_avilable_2: {
    en: "Click here to download",
    it: "Clicca qui per scaricare",
  },
  upper_lineApp_li1: {
    en: "For shipment 1.1.3.6. Look for the dangerous goods you are sending and calculate the 1,000",
    it: "Per invio 1.1.3.6. Cercare la merce pericolosa che stai inviando e fare il calcolo dei 1.000",
  },
  upper_lineApp_li2: {
    en: "keep a printable register of all shipments made as required by the decree, with counting and alarm not to exceed the maximum 24",
    it: "tenere il registro stampabile di tutte le spedizioni fatte, come richiesto dal decreto, con conteggio e allarme per non superare le 24 massime spedizioni previste",
  },

  upper_lineApp_li3: {
    en: "For tanker or bulk shipments check whether your dangerous goods can be sent applying this exemption",
    it: "Per spedizioni in Cisterna o alla Rinfusa Controllare se la tua merce pericolosa può essere inviata applicando questa esenzione",
  },
  upper_lineApp_li4: {
    en: "keep a printable register of all shipments made in tanks or in bulk, with counting and alarm not to exceed the maximum 12",
    it: "Tenere il registro stampabile di tutte le spedizioni fatte in cisterna o alla rinfusa, con conteggio e allarme per non superare le 12 massime spedizioni previste",
  },

  Home_placeholder_number: {
    en: "Enter 4 Digit UN Number Here",
    it: "Inserisci qui il numero ONU di 4 cifre",
  },
  Home_placeholder_name: {
    en: "Enter UN Name/Description Here",
    it: "Inserisci qui il nome/la descrizione del numero' ONU",
  },
  LOGIN_TO_PROCEED: {
    en: "PLease Login to Proceed",
    it: "Per favore effettua il login per procedere",
  },
};
