import { DELETE, GET, PATCH, POST } from "./Adapter/base";

export const createRegisterInvoice = (data) => {
  return POST(`invoices/store`, data);
};

export const updateRegisterInvoice = (data) => {
  return PATCH(`invoices/update/${data?.id}`, data);
};

export const getRegisterInvoice = () => {
  return GET(`invoices/list`);
};

export const checkLimit = () => {
  return GET(`invoices/check-limit`);
};

export const getRegisterById = (id) => {
  return GET(`invoices/show/${id}`);
};
export const deleteRegister = (id) => {
  return DELETE(`invoices/delete/${id}`);
};

export const uploadFile = (data) => {
  return POST(`invoices/upload`, data);
};

//invoices/show/1
