import React, { useState } from "react";
import ModalRegister from "../ModalRegister";
import boxLogo from "../../assets/images/delete.png";
import { useDispatch, useSelector } from "react-redux";
import { handleAllModals } from "../../redux/modalVisibility";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteConsignee } from "../../services/Consignee";
import { consignModelDelete } from "../../translations/Modeltranslation";

const DeleteConsignee = ({}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const delConsigneeModalState = useSelector(
    (state) => state.modalState.deleteConsigneeModal
  );

  const hideModal = (isSuccess) => {
    // setConsigneeModel(true);
    dispatch(
      handleAllModals({
        deleteConsigneeModal: { isVisible: false },
        refetchConsignee: isSuccess ? Math.random() : null,
      })
    );
  };

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteConsignee,
    onSuccess: (res) => {
      console.log("Submiting Response", res);
      // Invalidate and refetch
      if (res?.data?.success) {
        hideModal(true);
        // toast.error("Please Login to Proceed");
        setLoading(false);
      } else {
        queryClient.invalidateQueries({
          queryKey: ["consigneeListing"],
        });
        // toast.success(res.data.message);
        setLoading(false);
      }
    },
    onError: (err) => {
      // toast.error(
      //   err.response.status == 401
      //     ? "Please Login to Proceed"
      //     : err.response.data.message
      // );
    },
  });
  const lang = useSelector((state) => state.account?.lang);
  const { Consignee_delete_heading,consign_Model_Cancel,consign_Model_Proceed ,Consignee_delete_text,} = consignModelDelete;
  return (
    <React.Fragment>
      <ModalRegister>
        <div className="flex justify-center">
          <span className="flex bg-white rounded-full w-12 h-11 z-10">
            <img
              src={boxLogo}
              width={100}
              className="text-center m-auto justify-center"
            />
          </span>
        </div>
        <div className="flex flex-col items-center pb-3">
          <h3 className="font-bold  py-2">{Consignee_delete_heading[lang]}?</h3>
          <p className="text-center w-2/3">{Consignee_delete_text[lang]}</p>
        </div>
        <div className="flex justify-around pt-6">
          <button onClick={hideModal}>{consign_Model_Cancel[lang]}</button>
          <button
            onClick={() => mutation.mutate(delConsigneeModalState?.id)}
            className="bg-red-600 px-10  py-2 rounded-md text-white"
          >
           {consign_Model_Proceed[lang]}
          </button>
        </div>
      </ModalRegister>
    </React.Fragment>
  );
};

export default DeleteConsignee;
