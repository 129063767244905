import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleAllModals } from "../redux/modalVisibility";
import { updateAllBookmark } from "../redux/bookmarks";
import { logoutAction } from "../redux/account";

export default function useSubscription() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.account.user);
  const isAuthenticated = useSelector((state) => state.account.isAuthenticated);
  const checkSubscription = (data = userData) => {
    let trialDays = moment.unix(data?.trial?.end_date).diff(moment(), "days");
    debugger;
    if (data?.subscription) {
      if (moment.unix(data?.trial?.end_date).diff(moment(), "days") + 1 > 0) {
        dispatch(
          handleAllModals({
            expireModal: { isVisible: true, days: trialDays + 1 },
          })
        );
      }
    } else {
      if (trialDays + 1 > 0) {
        toast.success(`${login_toast[lang]}`);
        dispatch(
          handleAllModals({
            trialModal: { isVisible: true, days: trialDays + 1 },
          })
        );
      } else {
        dispatch(
          handleAllModals({
            expireModal: { isVisible: true, days: trialDays + 1 },
          })
        );
        dispatch(logoutAction());
        // alert("Logout");
        dispatch(updateAllBookmark([]));
      }
    }
  };

  return { checkSubscription };
}
