import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homeTranslation } from "../../../translations/home";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ListCard from "../../../components/ListCard";
import Loader from "../../../common/Loader";
import { toast } from "react-toastify";
import { updateAllBookmark } from "../../../redux/bookmarks";
import { getAllBookmarks } from "../../../services/Bookmarks";

export default function BookmarkTab() {
  const bookmarks = useSelector((state) => state.bookmarks);
  const isAuthenticated = useSelector((state) => state.account.isAuthenticated);
  const lang = useSelector((state) => state.account?.lang);

  console.log("Book Marks ", bookmarks);
  const {
    FIND_CHEMICAL,
    HERO_TEXT,
    UN_NAME,
    UN_NUMBER,
    NAME_EN,
    LOGIN_TO_PROCEED,
  } = homeTranslation;
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    isLoading: bookmarksLoading,
    error: bookmarksError,
    data: bookmarksD,
  } = useQuery({
    queryKey: ["bookmarksListing"],
    queryFn: getAllBookmarks,
    enabled: isAuthenticated,
  });

  const bookmarksData = bookmarksD?.data?.data;

  useEffect(() => {
    if (bookmarksData?.length > 0) {
      let resMark = bookmarksData?.map((d) => {
        return { ...d?.chemical, bookmark_id: d?.id };
      });
      console.log("resMark ", resMark);
      dispatch(updateAllBookmark(resMark));
    }
  }, [bookmarksData]);

  return (
    <div>
      {isAuthenticated && bookmarksLoading && <Loader />}
      <div className="py-10 container mx-auto">
        <div className="max-w-4xl mx-auto ">
          {!isAuthenticated && (
            <div className="text-center font-bold text-lg">
              {" "}
              {LOGIN_TO_PROCEED[lang]}{" "}
            </div>
          )}
          {bookmarks.map((chem) => {
            return <ListCard data={chem} />;
          })}
        </div>
      </div>
    </div>
  );
}
