import React from "react";
import { useDispatch } from "react-redux";
import { handleAllModals, hideAllModals } from "../redux/modalVisibility";

export default function ModalRegister({ children }) {
  const dispatch = useDispatch();
  const hideModal = () => {
    alert("clicked");
    dispatch(hideAllModals());
  };
  return (
    <React.Fragment>
      <div
        class="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"
          onClick={hideModal}
        ></div>
        <div
          class="fixed inset-0 z-10 w-screen overflow-y-auto"
          onClick={(e) => e.stopPropagation()}
        >
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div class=" px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div class="px-6 py-6 lg:px-8">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        onClick={hideModal}
        className="fixed inset-0 bg-gray-500 bg-opacity-50"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="relative p-4 bg-white lex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          {children}
        </div>
      </div> */}
    </React.Fragment>
  );
}
