import React, { useEffect, useState } from "react";
import boxLogo from "../../assets/images/box.png";
import { useDispatch, useSelector } from "react-redux";
import { removeRegister, updateRegister } from "../../redux/localRegisters";
import { useForm } from "react-hook-form";
import bulkLogo from "../../assets/images/bulk.jpg";

import AddRegisterModal from "../../components/AddRegisterModal";
import AfterProceedModal from "../../components/AfterProceedModal";
import useGetMultiplier from "../../hooks/useGetMultiplier";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getConsigneeList } from "../../services/Consignee";
import { useNavigate } from "react-router-dom";
import {
  createRegisterInvoice,
  updateRegisterInvoice,
  uploadFile,
} from "../../services/Registers";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";
import { shipmentCalTranslation } from "../../translations/about";

export default function EditDetailCard({ data, isDisabled }) {
  const { getMultiplier } = useGetMultiplier();
  const [modalVisible, setModalVisible] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState(null);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateRegisterInvoice,
    onSuccess: (res) => {
      // Invalidate and refetch
      if (res?.data?.success) {
        queryClient.invalidateQueries({
          queryKey: [`getRegisterById_${formData?.id}`, "registersListing"],
        });
        toast.success("Updated Successfuly");
        navigate(`/view_registers/${formData?.id}`);
        setLoading(false);
      } else {
        // queryClient.invalidateQueries({
        //   queryKey: ["consigneeListing"],
        // });
        // toast.success(res.data.message);
        // setPostLoading(false);
      }
    },
    onError: (err) => {},
  });

  const handleRegister = (dataa) => {
    const today = new Date(); // this will get the current date and time
    const formattedDate = `${today.getFullYear()}-${String(
      today.getMonth() + 1
    ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;

    const formsubmit = new FormData();
    let postData = {
      ...formData,
      consignee_id: parseInt(dataa?.consignee_id),
      invoice_date: formattedDate,
      items: formData.invoice_items,
      total_points: totalPoints,
      shipment_type: "Buld",
    };
    // debugger;
    setLoading(true);
    console.log("Dataa . File ", dataa, dataa?.file);
    formsubmit.append("file", dataa?.file[0]);
    dataa?.file?.length > 0
      ? uploadFile(formsubmit)
          .then((res) => {
            console.log("File Upload Res ", res);
            postData.file = res?.data?.data;
            mutation.mutate(postData);
          })
          .catch((err) => {
            console.log("File Upload Error ", err);
          })
      : mutation.mutate(postData);
    // setModalVisible(true);
  };

  const {
    isLoading: consigneeLoading,
    error: consigneeError,
    data: consignee,
  } = useQuery({
    queryKey: ["consigneeListing"],
    queryFn: getConsigneeList,
  });

  const consigneeData = consignee?.data?.data;

  useEffect(() => {
    if (consigneeData && data) {
      setValue("consignee_id", parseInt(formData?.consignee_id));
    }
  }, [consigneeData, data]);

  useEffect(() => {
    data && setFormData(data);
  }, [data]);

  const handleRemove = (id) => {
    dispatch(removeRegister(id));
  };

  useEffect(() => {
    let tempPoints = formData?.invoice_items?.reduce(
      (total, num) => total + parseInt(num.point),
      0
    );
    setTotalPoints(tempPoints);
  }, [formData]);

  const handleUpdate = (value, id, multiplier) => {
    const { invoice_items } = formData;
    let tempData = invoice_items?.map((d) => {
      console.log("d ", d);
      if (d?.chemical?.un_number == id) {
        return {
          ...d,
          quantity: value,
          point: multiplier == "unlimited" ? 0 : value * multiplier,
        };
      } else return d;
    });
    console.log("Temp Data ", tempData);
    setFormData({ ...formData, invoice_items: tempData });
  };

  const handleOnChange = (key, value) => {
    setFormData((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const handleFileChange = (event) => {
    console.log("Event File  ", URL.createObjectURL(event?.target?.files[0]));
    setSelectedFile(event.target.files[0]);
    const formDataM = new FormData();
    formDataM.append("file", event.target.files[0]);
    // formDataM.append("file", {...event.target.files[0], uri: URL.createObjectURL(event?.target?.files[0])});
    console.log("Selected File v ", formDataM);
    setFormData((prev) => {
      return {
        ...prev,
        file: formDataM,
      };
    });
  };
  const lang = useSelector((state) => state.account?.lang);
  const {
    shipment_Categoria,
    shipment_Pg,
    shipment_allow,
    shipment_class,
    shipment_label,
    shipment_list,
    update_register,
    shipment_quantity,
    shipment_point,
    shipment_subheading,
    shipment_not_allow,
    TOTAL_POINTS,
    CATEGORY_TRC,
  } = shipmentCalTranslation;
  return (
    <React.Fragment>
      {loading && <Loader />}
      <div class="bg-white border rounded-lg shadow-md pt-8">
        <form onSubmit={handleSubmit(handleRegister)}>
          {/* <!-- Card Image --> */}
          {/* <!-- Card Content --> */}
          {/* <!-- component --> */}
          <span
            className="flex bg-amber-500 p-3 rounded-lg max-w-lg mx-auto items-center
        "
          >
            <i className="fa fa-user-circle-o text-3xl" aria-hidden="true"></i>
            {!isDisabled ? (
              <select
                disabled={isDisabled}
                // defaultValue={formData?.consignee_id}

                name="consignee_id"
                className="block w-full p-2 bg-amber-500  text-sm text-gray-900 "
                {...register("consignee_id", {
                  // required: "required",
                  onChange: (e) =>
                    handleOnChange("consignee_id", e.target.value),
                })}
              >
                <option value="" disabled>
                  Choose a Consignee
                </option>
                {consigneeData?.map((c, i) => {
                  return (
                    <option
                      selected={formData?.consignee_id == c?.id}
                      key={i}
                      value={c?.id}
                    >
                      {" "}
                      {c?.name}{" "}
                    </option>
                  );
                })}
              </select>
            ) : (
              <span className="ps-5">{formData?.consignee?.name}</span>
            )}
          </span>
          {!isDisabled && (
            <div>
              <div class="extraOutline py-5 bg-white bg-whtie m-auto rounded-lg max-w-lg">
                <div class="file_upload p-5 flex relative border-4 border-dotted border-gray-300 rounded-lg">
                  <svg
                    class="text-indigo-500 w-12 mx-auto me-4 mb-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <div class="input_field flex flex-col w-max mx-auto text-center">
                    <label>
                      <input
                        class="text-sm cursor-pointer w-36 hidden"
                        type="file"
                        // onChange={}
                        // multiple
                        {...register("file")}
                      />
                      <div class="text flex bg-indigo-600 text-white border border-gray-300 rounded font-semibold cursor-pointer p-1 px-3 hover:bg-indigo-500">
                        Select File
                      </div>
                    </label>

                    <div class="title text-indigo-500 uppercase hidden">
                      or drop files here
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <hr className="m-4" />
          <div className="p-4">
            {formData?.shipment_type == "1136" && (
              <div className="text-center">
                <>
                  <p className="text-sm">{TOTAL_POINTS[lang]}</p>
                  <h2
                    class={`text-4xl my-1 font-black  ${
                      totalPoints > 1000 ? "text-red-600" : "text-green-600"
                    }  `}
                  >
                    {" "}
                    {totalPoints}{" "}
                  </h2>
                  <p
                    className={`uppercase text-sm leading-tight font-black ${
                      totalPoints > 1000 ? "text-red-600" : "text-green-600"
                    } `}
                  >
                    {totalPoints > 1000
                      ? `${shipment_not_allow[lang]}`
                      : `${shipment_allow[lang]}`}
                  </p>
                </>
              </div>
            )}

            {formData?.invoice_items?.map((item, i) => {
              // const {
              //   register,
              //   handleSubmit,
              //   setValue,
              //   reset,
              //   formState: { errors },
              // } = useForm();
              const { chemical } = item;
              // setValue("qty", sub?.qty);
              const multiplier = getMultiplier(
                chemical?.un_number,
                chemical?.trc_transport_category
              );

              return (
                <div className="border m-2 mt-5 p-4 rounded">
                  <div className="flex justify-between mb-2">
                    <div className="flex items-center">
                      <span className="bg-green-600 h-8 w-8 rounded-full text-center text-white font-bold p-1 me-3">
                        {i + 1}
                      </span>
                      <span className="flex bg-white rounded-full w-12 h-11 z-1">
                        <img
                          src={
                            formData?.shipment_type == "1136"
                              ? boxLogo
                              : bulkLogo
                          }
                          width={30}
                          className="text-center m-auto justify-center"
                        />
                      </span>
                      <span className="bg-[#f89329] flex uppercase text-sm font-bold py-1 pl-7 pr-4 h-8 rounded-r-full -ml-5 my-auto">
                        <span className="my-auto">
                          {" "}
                          {item?.chemical?.un_number}{" "}
                        </span>
                      </span>
                    </div>

                    {!isDisabled && (
                      <button
                        className="flex"
                        onClick={() => handleRemove(item?.chemical?.un_number)}
                      >
                        <i className="fa fa-trash text-white w-8 text-center h-8 rounded-full bg-red-700  flex items-center justify-center" />
                      </button>
                    )}
                  </div>

                  <p class="text-gray-600">{item?.chemical?.name_en}</p>

                  <div className=" grid grid-cols-4 text-sm py-3">
                    <div className="border-r-1 text-start px-4 border-r">
                      <div className="font-bold">{CATEGORY_TRC[lang]}</div>
                      <div className="text-xs text-gray-700">
                        {item?.chemical?.trc_transport_category}
                      </div>
                    </div>
                    <div className="px-4 border-r text-start">
                      <div className="font-bold">{shipment_class[lang]}</div>
                      <div className="text-xs text-gray-700">
                        {item?.chemical?.class}{" "}
                      </div>
                    </div>
                    <div className="border-r text-start px-4">
                      <div className="font-bold">{shipment_label[lang]}</div>
                      <div className="text-xs text-gray-700">
                        {" "}
                        {item?.chemical?.label}{" "}
                      </div>
                    </div>
                    <div className="text-start px-4">
                      <div className="font-bold">{shipment_Pg[lang]}</div>
                      <div className="text-xs text-gray-700">
                        {item?.chemical?.packing_group}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 text-sm pt-3 pb-2 ">
                    <label className="font-bold col-start-1 col-span-1 py-3  px-4">
                      {shipment_quantity[lang]}:
                    </label>
                    <div className="col-start-2 col-span-4 flex">
                      <input
                        maxLength={4}
                        // value={item?.qty}
                        disabled={isDisabled}
                        defaultValue={item?.quantity}
                        onChange={(e) =>
                          handleUpdate(
                            e?.target?.value,
                            chemical?.un_number,
                            multiplier
                          )
                        }
                        name="qty"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-48 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      />
                    </div>
                    {formData?.shipment_type == "1136" &&
                      multiplier != "unlimited" && (
                        <div className="col-start-6 col-span-1 text-center font-bold">
                          x {multiplier} = {item?.quantity * multiplier}{" "}
                          {shipment_point[lang]}
                        </div>
                      )}
                  </div>
                </div>
              );
            })}
          </div>
          {/* <!-- Card Actions --> */}
          {!isDisabled && (
            <>
              <div>
                {/* <button className="bg-red-700 text-white rounded-bl-lg w-[50%] py-4">
                <i className="fa fa-trash me-2"></i>
                <span className="text-sm">Clear Shipment List</span>
              </button> */}
                <button
                  type="submit"
                  className="rounded-ee-lg text-white bg-green-600 w-full rounded-bl-lg py-4"
                >
                  <i className="fa fa-check-circle-o me-2"></i>
                  <span className="text-sm">{update_register[lang]}</span>
                </button>
              </div>
            </>
          )}{" "}
        </form>
      </div>
    </React.Fragment>
  );
}
