import React, { useEffect, useState } from "react";
import boxLogo from "../../assets/images/box.png";
import ModalRegister from "../ModalRegister";
import { useDispatch, useSelector } from "react-redux";
import { handleAllModals } from "../../redux/modalVisibility";
import { addLocalRegisters } from "../../redux/localRegisters";
import bulkLogo from "../../assets/images/bulk.jpg";
import useGetMultiplier from "../../hooks/useGetMultiplier";
import { homeTranslation } from "../../translations/home";
import { useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { getPackagingList } from "../../services/Packaging";
import { toast } from "react-toastify";

export default function AddSubstanceModal({}) {
  const { getMultiplier } = useGetMultiplier();
  const dispatch = useDispatch();
  const [multiplier, setMultiplier] = useState(1);
  const [qty, setQty] = useState(0);
  const shipmentType = useSelector((state) => state.account.shipmentType);

  const {
    isLoading: packagingLoading,
    error: packagingError,
    data: packaging,
  } = useQuery({
    queryKey: ["packagingListing"],
    queryFn: getPackagingList,
  });

  console.log("Consignee Data ", packaging);
  const packagingData = packaging?.data?.data;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const hideModal = () => {
    dispatch(
      handleAllModals({
        addSubstanceModal: { isVisible: false },
      })
    );
  };

  const substanceModalState = useSelector(
    (state) => state.modalState.addSubstanceModal
  );
  const data = substanceModalState?.data;

  useEffect(() => {
    let tempMuliplier = getMultiplier(
      data?.un_number,
      data?.trc_transport_category
    );

    setMultiplier(tempMuliplier);
    console.log("Temp Multiplie ", tempMuliplier);
  }, [substanceModalState?.data]);

  const onSubmit = (formData) => {
    console.log("Form Data ", formData);
    dispatch(
      addLocalRegisters({
        ...data,
        multiplier,
        qty,
        points: multiplier == "unlimited" ? 0 : multiplier * qty,
        ...formData,
      })
    );
    dispatch(
      handleAllModals({
        addSubstanceModal: {
          isVisible: false,
          data: null,
        },
      })
    );
    toast.success('Aggiunto A "Calcolo Spedizione');
  };
  const lang = useSelector((state) => state.account?.lang);
  const {
    model_amount_heading,
    model_amount_subheading,
    model_amount_cancel,
    model_amount_max,
    model_amount_maxPoint,
    model_amount_proceed,
    CHOOSE_PKG,
  } = homeTranslation;

  console.log("Errors ", errors);
  return (
    <React.Fragment>
      {substanceModalState?.isVisible && (
        <ModalRegister>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-center">
              <span className="flex bg-white rounded-full w-12 h-11 z-10">
                <img
                  src={shipmentType == "1136" ? boxLogo : bulkLogo}
                  width={30}
                  className="text-center m-auto justify-center"
                />
              </span>
              <span className="bg-[#f89329] flex uppercase text-sm font-bold py-1 pl-7 pr-4 h-8 rounded-r-full -ml-5 my-auto">
                <span className="my-auto"> {data?.un_number} </span>
              </span>
            </div>
            <div className="flex flex-col items-center ">
              <h3 className="font-bold  py-2">{model_amount_heading[lang]}</h3>
              <p>{model_amount_subheading[lang]}</p>
            </div>
            {shipmentType == "1136" && (
              <div className="text-center py-4">
                <span className="rounded bg-yellow-300 py-1 px-2 my-3">
                  {multiplier != "unlimited"
                    ? `${model_amount_max[lang]} ${parseInt(1000 / multiplier)}`
                    : multiplier}
                </span>
              </div>
            )}
            <div className=" flex py-2 justify-evenly items-center">
              <input
                type="number"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-48 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                {...register("qty", {
                  required: "required",
                  min: 1,
                  onChange: (e) => setQty(e?.target?.value),
                })}
              />
              {shipmentType == "1136" && multiplier != "unlimited" && (
                <>
                  x {multiplier} =
                  <p
                    className={
                      multiplier != "unlimited" && qty * multiplier > 1000
                        ? `text-red-700`
                        : `text-black`
                    }
                  >
                    {qty * multiplier} {model_amount_maxPoint[lang]}
                  </p>
                </>
              )}
            </div>
            {shipmentType == "1136" && (
              <>
                {multiplier != "unlimited" && qty * multiplier > 1000 && (
                  <p className="text-red-700 text-center mb-2">Not Valid</p>
                )}
              </>
            )}

            {errors.qty && (
              <p className="text-red-800 text-center mb-1">
                {errors.qty.message}
              </p>
            )}
            {shipmentType == "1136" && (
              <span className="w-[85%]">
                <div className=" flex py-2 justify-evenly items-center">
                  <select
                    id="small"
                    name="packaging_id"
                    className="block w-full p-3 rounded-lg text-sm text-gray-900  border bg-gray-50 border-gray-300"
                    {...register("packaging_id", {
                      required: "Ricordati Di Scegliere L' Ihballaggio Utilizzae",
                    })}
                  >
                    <option selected value="" disabled>
                      {CHOOSE_PKG[lang]}
                    </option>
                    {packagingData?.map((c, i) => {
                      return (
                        <option key={i} value={c?.id}>
                          {" "}
                          {lang == "en" ? c?.name_en : c?.name_it}{" "}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {errors.packaging_id && (
                  <p className="text-red-800 mb-1 text-center">
                    {errors.packaging_id.message}
                  </p>
                )}{" "}
              </span>
            )}
            <div className="flex justify-around">
              <button
                type="button"
                className=" border border-gray-300 shadow-sm px-10 py-2 rounded-md"
                onClick={hideModal}
              >
                {model_amount_cancel[lang]}
              </button>
              <button
                // onClick={handleSubmit}
                type="submit"
                disabled={
                  shipmentType == "1136" &&
                  multiplier != "unlimited" &&
                  qty * multiplier > 1000
                }
                className="bg-green-600 px-10  py-2 rounded-md text-white shadow-sm"
              >
                {model_amount_proceed[lang]}
              </button>
            </div>
          </form>
        </ModalRegister>
      )}
    </React.Fragment>
  );
}
