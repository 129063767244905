import React from "react";
import { useSelector } from "react-redux";

export default function useAlreadyBookmarked() {
  const bookmarks = useSelector((state) => state.bookmarks);

  const IsAlreadyMarked = (data) => {
    return bookmarks?.some((d) => {
      return d.id == data?.id;
    });
  };

  return { IsAlreadyMarked };
}
