import React, { useEffect, useState } from "react";
import boxLogo from "../../assets/images/box.png";
import { useDispatch, useSelector } from "react-redux";
import { removeRegister, updateRegister } from "../../redux/localRegisters";
import { useForm } from "react-hook-form";
import bulkLogo from "../../assets/images/bulk.jpg";
import logo from "../../assets/images/consignee.png";

import AddRegisterModal from "../../components/AddRegisterModal";
import AfterProceedModal from "../../components/AfterProceedModal";
import useGetMultiplier from "../../hooks/useGetMultiplier";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getConsigneeList } from "../../services/Consignee";
import { useNavigate } from "react-router-dom";
import {
  createRegisterInvoice,
  updateRegisterInvoice,
} from "../../services/Registers";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";
import {
  registerTranslation,
  shipmentCalTranslation,
} from "../../translations/about";
import moment from "moment";

export default function ViewDetailCard({ data, shipment_no, isDisabled }) {
  const { getMultiplier } = useGetMultiplier();
  const [modalVisible, setModalVisible] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileData, setFileData] = useState(null);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateRegisterInvoice,
    onSuccess: (res) => {
      console.log("Register Submiting Response", res);
      // Invalidate and refetch
      if (res?.data?.success) {
        queryClient.invalidateQueries({
          queryKey: [`getRegisterById_${formData?.id}`, "registersListing"],
        });
        toast.success("Updated Successfuly");
        setLoading(false);
        // navigate("/registers");
        // dispatch(clearAllRegisters());
        // hideConsigneeModal(true);
        // toast.error("Please Login to Proceed");
        // reset();
        // setPostLoading(false);
        // dispatch(
        //   handleAllModals({
        //     afterProceedModal: { isVisible: false },
        //   })
        // );
      } else {
        // queryClient.invalidateQueries({
        //   queryKey: ["consigneeListing"],
        // });
        // toast.success(res.data.message);
        // setPostLoading(false);
      }
    },
    onError: (err) => {},
  });

  const {
    isLoading: consigneeLoading,
    error: consigneeError,
    data: consignee,
  } = useQuery({
    queryKey: ["consigneeListing"],
    queryFn: getConsigneeList,
  });

  console.log("Consignee Data ", consignee);
  const consigneeData = consignee?.data?.data;

  useEffect(() => {
    if (consigneeData) {
      setValue("consignee_id", formData?.consignee_id);
    }
  }, [consigneeData, data]);

  useEffect(() => {
    data && setFormData(data);
  }, [data]);

  const handleRemove = (id) => {
    dispatch(removeRegister(id));
  };

  useEffect(() => {
    let tempPoints = formData?.invoice_items?.reduce(
      (total, num) => total + parseInt(num.point),
      0
    );
    setTotalPoints(tempPoints);
  }, [formData]);

  const handleUpdate = (value, id, multiplier) => {
    // dispatch(updateRegister({ value, id }));
    const { invoice_items } = formData;
    let tempData = invoice_items?.map((d) => {
      console.log("d ", d);
      if (d?.chemical?.un_number == id) {
        return {
          ...d,
          quantity: value,
          point: multiplier == "unlimited" ? 0 : value * multiplier,
        };
        // d.quantity = value;
      } else return d;
    });
    console.log("Temp Data ", tempData);
    setFormData({ ...formData, invoice_items: tempData });
  };

  const shipmentType = useSelector((state) => state.account.shipmentType);

  const afterProceedModalState = useSelector(
    (state) => state.modalState.afterProceedModal
  );

  console.log("View Details ", formData);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  let { invoice_date } = formData;
  let invoiceDate = new Date(invoice_date).toDateString();
  const lang = useSelector((state) => state.account?.lang);
  const {
    register_view_shippment_consignee,
    register_view_shippment_date,
    register_view_shippment_type,
    register_no,
    register_point,
  } = registerTranslation;
  const {
    shipment_Categoria,
    shipment_Pg,
    shipment_class,
    shipment_label,
    shipment_quantity,
    shipment_point,
  } = shipmentCalTranslation;
  return (
    <React.Fragment>
      {/* {modalVisible && (
        <AddRegisterModal hideModal={() => setModalVisible(false)} />
      )}

      {afterProceedModalState?.isVisible && <AfterProceedModal />} */}
      {loading && <Loader />}
      <div class="bg-white border rounded-lg shadow-md p-5">
        {/* <!-- Card Image --> */}

        {/* <!-- Card Content --> */}
        {/* <!-- component --> */}

        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <div className="border-e-2 p-5">
              {formData?.shipment_type == "1136" ? (
                <img width={100} src={boxLogo} />
              ) : (
                <img width={100} src={bulkLogo} />
              )}{" "}
            </div>
            <div className="px-5 flex flex-col justify-around gap-3">
              <div>
                <p className="text-gray-600">
                  {register_view_shippment_type[lang]}
                </p>
                <b>
                  {formData.shipment_type == "1136"
                    ? `ADR 1.1.3.6 ${
                        formData?.sub_type ? "-" + formData?.sub_type : ""
                      }`
                    : "Bulk"}{" "}
                </b>
              </div>
              <div>
                <p className="text-gray-600">
                  {register_view_shippment_date[lang]}
                </p>
                <b>
                  {moment(formData?.invoice_date).utc().format("DD MMM YYYY")}
                </b>
              </div>
              <div></div>
            </div>
          </div>
          <div className="flex flex-col gap-3 text-end">
            <div>
              <p className="text-gray-600">{register_no[lang]}</p>
              <b> {shipment_no} </b>
            </div>
            <div>
              <p className="text-gray-600">{register_point[lang]}</p>
              <p className="font-bold text-green-600">
                {" "}
                {formData?.total_points}{" "}
              </p>
            </div>
          </div>
        </div>
        {formData && (
          <div className="text-center py-4 flex justify-center">
            <a
              target="_blank"
              rel="noopener"
              href={formData?.file}
              className={`cursor-pointer min-w-80 flex items-center  border-black border-2 rounded py-2 px-4 ${
                formData?.file
                  ? "border-amber-500 bg-amber-500 bg-opacity-50"
                  : "bg-gray-300 border-gray-300 shadow"
              }`}
            >
              <i
                className={`fa me-2 ${
                  formData?.file ? "fa-file-o" : "fa-ban"
                } `}
              />
              {formData?.file
                ? "Click Here to View Attached File"
                : "No Shipment Transport Document Added"}
            </a>
          </div>
        )}
        <div className="py-5">
          <p className="text-gray-600">
            {register_view_shippment_consignee[lang]}
          </p>
          <div
            className="flex py-3 rounded-lg items-center
          "
          >
            <span className="flex flex-col bg-amber-500 bg-opacity-50 rounded-full h-12 w-12 z-1 p-2">
              <img
                src={logo}
                // width={200}
                className="text-center justify-center object-contain"
              />
            </span>
            {/* <i
              className="fa fa-user-circle-o text-5xl text-gray-400"
              aria-hidden="true"
            ></i> */}
            <div className="ps-5">
              <span className=" font-semibold">
                {formData?.consignee?.name}
              </span>{" "}
              : <div>{formData?.consignee?.address} </div>
              <span className="font-semibold">
                {formData?.consignee?.city_postal_code}
              </span>
            </div>
          </div>
        </div>
        {!isDisabled && (
          <div>
            <div class="extraOutline py-5 bg-white bg-whtie m-auto rounded-lg max-w-lg">
              <div class="file_upload p-5 flex relative border-4 border-dotted border-gray-300 rounded-lg">
                <svg
                  class="text-indigo-500 w-12 mx-auto me-4 mb-4"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  />
                </svg>
                <div class="input_field flex flex-col w-max mx-auto text-center">
                  <label>
                    <input
                      class="text-sm cursor-pointer w-36 hidden"
                      type="file"
                      // multiple
                      {...register("file", {
                        onChange: handleFileChange,
                      })}
                    />
                    <div class="text flex bg-indigo-600 text-white border border-gray-300 rounded font-semibold cursor-pointer p-1 px-3 hover:bg-indigo-500">
                      Select File
                    </div>
                  </label>

                  <div class="title text-indigo-500 uppercase hidden">
                    or drop files here
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <hr className="m-4" />
        <div className="p-4">
          {formData?.invoice_items?.map((item, i) => {
            // const {
            //   register,
            //   handleSubmit,
            //   setValue,
            //   reset,
            //   formState: { errors },
            // } = useForm();
            const { chemical } = item;
            // setValue("qty", sub?.qty);
            const multiplier = getMultiplier(
              chemical?.un_number,
              chemical?.trc_transport_category
            );

            console.log("Multiplier ", multiplier);
            return (
              <div className="border m-2 mt-5 p-4 rounded">
                <div className="flex justify-between mb-2">
                  <div className="flex items-center">
                    <span className="bg-green-600 h-8 w-8 rounded-full text-center text-white font-bold p-1 me-3">
                      {i + 1}
                    </span>
                    <span className="flex bg-white rounded-full w-12 h-11 z-1">
                      <img
                        src={
                          formData?.shipment_type == "1136" ? boxLogo : bulkLogo
                        }
                        width={40}
                        className="text-center m-auto justify-center"
                      />
                    </span>
                    <span className="bg-[#f89329] flex uppercase text-sm font-bold py-1 pl-7 pr-4 h-8 rounded-r-full -ml-5 my-auto">
                      <span className="my-auto">
                        {" "}
                        {item?.chemical?.un_number}{" "}
                      </span>
                    </span>
                  </div>

                  {!isDisabled && (
                    <button
                      className="flex"
                      onClick={() => handleRemove(item?.chemical?.un_number)}
                    >
                      <i className="fa fa-trash text-white w-8 text-center h-8 rounded-full bg-red-700  flex items-center justify-center" />
                    </button>
                  )}
                </div>

                <p class="text-gray-600">
                  {lang == "en"
                    ? item?.chemical?.name_en
                    : item?.chemical?.name_it}
                </p>

                <div className=" grid grid-cols-4 text-sm py-3">
                  <div className="border-r-1 text-start px-4 border-r">
                    <div className="font-bold">{shipment_Categoria[lang]}</div>
                    <div className="text-xs text-gray-700">
                      {item?.chemical?.trc_transport_category}
                    </div>
                  </div>
                  <div className="px-4 border-r text-start">
                    <div className="font-bold">{shipment_class[lang]}</div>
                    <div className="text-xs text-gray-700">
                      {item?.chemical?.class}{" "}
                    </div>
                  </div>
                  <div className="border-r text-start px-4">
                    <div className="font-bold">{shipment_label[lang]}</div>
                    <div className="text-xs text-gray-700">
                      {" "}
                      {item?.chemical?.label}{" "}
                    </div>
                  </div>
                  <div className="text-start px-4">
                    <div className="font-bold">{shipment_Pg[lang]}</div>
                    <div className="text-xs text-gray-700">
                      {item?.chemical?.packing_group}
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-2 text-sm pt-3 pb-2 ">
                  <label className="font-bold col-start-1 col-span-1 py-3  px-4">
                    {shipment_quantity[lang]}:
                  </label>
                  <div className="col-start-2 col-span-4 flex">
                    <input
                      maxLength={4}
                      // value={item?.qty}
                      disabled={isDisabled}
                      defaultValue={item?.quantity}
                      onChange={(e) =>
                        handleUpdate(
                          e?.target?.value,
                          chemical?.un_number,
                          multiplier
                        )
                      }
                      name="qty"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-48 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    />
                  </div>
                  {formData?.shipment_type == "1136" &&
                    multiplier != "unlimited" && (
                      <div className="col-start-6 col-span-1 text-center font-bold">
                        x {multiplier} = {item?.quantity * multiplier}{" "}
                        {shipment_point[lang]}
                      </div>
                    )}
                </div>

                {formData?.shipment_type != "1136" && (
                  <div className="flex items-center gap-2 text-sm pt-3 pb-2 ">
                    <label className="font-bold col-start-1 col-span-1 py-3  px-4">
                      Packaging:
                    </label>
                    <p class="text-black font-bold bg-[#f89329] py-2 px-4 rounded-full">
                      {lang == "en"
                        ? item?.packaging?.name_en
                        : item?.packaging?.name_it}
                    </p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
}
