import React, { useLayoutEffect, useState } from "react";
 
import { useQuery } from "@tanstack/react-query";
import Loader from "../../common/Loader";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux"; 
import { homeTranslation } from "../../translations/home";
import { useParams } from "react-router-dom";
import { getRegisterById } from "../../services/Registers"; 
import EditDetailCard from "./EditDetailCard";
useQuery;
export default function EditRegisters() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [searchType, setSearchType] = useState("un_number");
  const [searchData, setSearchData] = useState({ searchValue: "0004" });
  const localRegisters = useSelector((state) => state.localRegisters);
  const lang = useSelector((state) => state.account?.lang);
  const { id } = useParams();
  const { FIND_CHEMICAL, HERO_TEXT, UN_NAME, UN_NUMBER, NAME_EN } =
    homeTranslation;
  console.log("Params ", id);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    isLoading: registerByIdLoading,
    error: registerByIdError,
    data: registerById,
  } = useQuery({
    queryKey: [`getRegisterById_${id}`, id],
    queryFn: () => getRegisterById(id),
  });

  console.log("Get By ID Register", registerById?.data?.data);
  const registerData = registerById?.data?.data;

  const handleSearch = (data) => {
    console.log("Search Data ", data);
    setSearchData(data);
  };

  return (
    <React.Fragment>
      {registerByIdLoading && <Loader />}

      <div className="py-10 container mx-auto">
        <div className="max-w-4xl mx-auto ">
          <h1 className="font-bold text-3xl mb-8">Edit Register</h1>
        </div>

        <div class="max-w-4xl mx-auto">
          {/* Detail Card  */}
          <EditDetailCard data={registerData} isDisabled={false} />
        </div>
      </div>
    </React.Fragment>
  );
}
