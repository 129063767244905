import React from "react";
import ModalContainer from "../ModalContainer";
import { useForm } from "react-hook-form";
import { useState } from "react";
import {
  accountVerify,
  resendOTP,
  signup,
  verifyOTP,
} from "../../services/Account";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";
import { handleAllModals } from "../../redux/modalVisibility";
import {
  signModelTranslation,
  toastNotification,
} from "../../translations/Modeltranslation";

export default function VerifyOTPModal({ email }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.account?.lang);
  const { REGISTER_TOAST, OTP_VERIFIED, OTP_SENT, ACC_VERIFIED, INVALID_OTP } =
    toastNotification;

  const hideModal = () => {
    dispatch(handleAllModals({ verifyOTPModal: { isVisible: false } }));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    const { otp } = data;
    let postData = {
      email,
      otp,
    };
    setLoading(true);
    console.log("Login Data ", postData);
    verifyOTP(postData)
      .then((res) => {
        console.log("otp Res", res);
        if (res?.data?.success && res?.data?.data) {
          hideModal();
          setLoading(false);
          toast.success(OTP_VERIFIED[lang]);
          accountVerify({ email: email }).then((accRes) => {
            console.log("accVerRes ", accRes);
            if (res?.data?.success) {
              toast.success(ACC_VERIFIED[lang]);
            }
          });
        } else {
          setLoading(false);
          toast?.error(INVALID_OTP[lang]);
        }
      })
      .catch((err) => {
        console.log("Login Err ", err);
        setLoading(false);
      });
  };
  const sendOTP = () => {
    resendOTP({ email: email }).then((res) => {
      console.log("otp resend RES ", res);
      if (res?.data?.success) {
        toast.success(OTP_SENT[lang]);
      }
    });
  };
  const {
    Signup_heading,
    Confirmpassword,
    Email,
    Last,
    Name,
    Password,
    required,
    email_required,
    register_btn,
    VERIFY_OTP,
    OTP,
    RESEND_OTP,
  } = signModelTranslation;
  return (
    <React.Fragment>
      <ModalContainer hideModal={hideModal} title={VERIFY_OTP[lang]}>
        {loading && <Loader />}
        <form onSubmit={handleSubmit(onSubmit)} class="space-y-6" action="#">
          <div>
            <label
              for="otp"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              {OTP[lang]}
            </label>
            <input
              type="number"
              name=" otp"
              placeholder="••••••••"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              {...register("otp", {
                required: true,
                minLength: 6,
              })}
            />
          </div>
          <button
            // onClick={openRegisterModal}
            onClick={sendOTP}
            class="text-blue-700 ms-2 hover:underline dark:text-blue-500"
          >
            {RESEND_OTP[lang]}
          </button>

          <button
            // type="submit"
            className="w-full text-white bg-[#f89329] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800  uppercase"
          >
            {VERIFY_OTP[lang]}
          </button>
        </form>
      </ModalContainer>
    </React.Fragment>
  );
}
