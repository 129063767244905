import { createSlice } from "@reduxjs/toolkit";
import session from "../services/utils/session";

const initialState = session.get("bookmarks") || [];

export const bookmarkReducer = createSlice({
  name: "registers",
  initialState,
  reducers: {
    addBookmark: (state, action) => {
      let tempState = state;
      tempState.push(action?.payload);
      session.set("bookmarks", tempState);
      return tempState;
    },
    removeBookmark: (state, action) => {
      let tempState = state?.filter((d) => d?.id != action.payload);
      session.set("bookmarks", tempState);
      return tempState;
    },
    updateAllBookmark: (state, action) => {
      session.set("bookmarks", action?.payload);
      return action?.payload;
    },

    clearAllBookmarks: (state, action) => {
      session.set("bookmarks", []);
      return [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addBookmark,
  removeBookmark,
  clearAllBookmarks,
  updateAllBookmark,
} = bookmarkReducer.actions;

export default bookmarkReducer.reducer;
