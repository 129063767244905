export const consignModel = {
  CITY_POSTAL_CODE: {
    en: "City Postal Code",
    it: "Codice postale della città",
  },
  consign_Name_Model: {
    en: "Name",
    it: "Nome",
  },
  consign_phone_Model: {
    en: "phone_Number",
    it: "numero di telefono",
  },
  consign_Address_Model: {
    en: "Address",
    it: "indirizzo",
  },
  consign_submit_Model: {
    en: "submit",
    it: "invia",
  },
  Consignee_BUTTON: {
    en: "Add new Consignee",
    it: "Aggiungi nuovo destinatario",
  },
  Consignee_Create: {
    en: "Created at",
    it: "Creato a",
  },
};

export const consignModelDelete = {
  Consignee_delete_heading: {
    en: "Are you sure",
    it: "Sei sicuro",
  },
  Consignee_delete_text: {
    en: "You want to delete Consignee",
    it: "Vuoi eliminare il destinatario",
  },
  register_delet: {
    en: "You want to delete Shippment",
    it: "Vuoi eliminare la spedizione",
  },
  consign_Model_Cancel: {
    en: "Cancel",
    it: "Annulla",
  },
  consign_Model_Proceed: {
    en: "Proceed",
    it: "Procedere",
  },
  shipment_subheading1: {
    en: "You want to",
    it: "Tu vuoi",
  },
  shipment_subheading: {
    en: " Clear All Calculations",
    it: "Cancella tutti i calcoli",
  },
};

export const consigneeListModel = {
  consign_Model_Proceed: {
    en: "Proceed",
    it: "Procedere",
  },
};

export const shipmentModal = {
  shipment_warning: {
    en: "Warning",
    it: "Attenzione",
  },
  shipment_text: {
    en: " Are you sure you want to add this Shipment into registers",
    it: "Confermi che vuoi aggiungere questa spedizione al Registro spedizioni?",
  },
  shipment_subtext_you: {
    en: "your",
    it: "La tua situazione per i limiti spedizione in ",
  },
  shipment_textbold: {
    en: " Limits Overview",
    it: "Panoramica dei limiti",
  },
  shipment_outof: {
    en: "out of",
    it: "fuori da",
  },
  shipment_Model_Cancel: {
    en: "Cancel",
    it: "Annulla",
  },
  shipment_Model_Proceed: {
    en: "Proceed",
    it: "Procedi",
  },
};
export const required = {
  error_required: {
    en: "Required",
    it: "Necessario",
  },
};

export const toastNotification = {
  INVALID_OTP: {
    en: "Invalid OTP",
    it: "OTP non valida",
  },
  ACC_VERIFIED: {
    en: "Your account verified successfully.",
    it: "Il tuo account è stato verificato con successo.",
  },
  OTP_SENT: {
    en: "OTP send successfully.",
    it: "Invio OTP riuscito.",
  },
  OTP_VERIFIED: {
    en: "OTP Verified successfully.",
    it: "OTP verificato con successo.",
  },
  REGISTER_TOAST: {
    en: "Your account has been created successfully. Check your email for account verification.",
    it: "Il tuo account è stato creato con successo. Controlla la tua email per la verifica dell'account.",
  },
  Login_shipment: {
    en: "Please Login to add UN for shipment calculation",
    it: "Effettua il login per aggiungere le Nazioni Unite per il calcolo della spedizione",
  },
  listCard_toast: {
    en: "Already Added to Shipment Calculations",
    it: "Già aggiunto ai calcoli della spedizione",
  },
  listCard_infoToast: {
    en: "This Type of Substance is not From Category Bulk",
    it: "Questo tipo di sostanza non appartiene alla categoria sfusa",
  },
  login_toast: {
    en: "Logged in Successfuly",
    it: "Accesso effettuato con successo",
  },
};

export const signModelTranslation = {
  RESEND_OTP: {
    en: "Resend OTP",
    it: "Invia nuovamente OTP",
  },
  VERIFY_OTP: {
    en: "Verify OTP",
    it: "Verificare OTP",
  },
  OTP: {
    en: "OTP",
    it: "OTP",
  },
  Signup_heading: {
    en: "Signup",
    it: "Iscrizione",
  },
  Name: {
    en: "First Name",
    it: "Nome",
  },
  Last: {
    en: "Last Name",
    it: "Cognome",
  },
  Email: {
    en: "Email",
    it: "E-mail",
  },
  Password: {
    en: "Password",
    it: "Parola d'ordine",
  },
  Confirmpassword: {
    en: "Confirm password",
    it: "Conferma password",
  },
  required: {
    en: "required",
    it: "necessaria",
  },
  email_required: {
    en: "Must be Valid Email",
    it: "Deve essere un'e-mail valida",
  },
  register_btn: {
    en: "Register",
    it: "Registrati",
  },
  login_Account: {
    en: "Login to your account",
    it: "Accedi al tuo account",
  },
  create_account: {
    en: " Create account",
    it: "Creare un account",
  },
  lost_password: {
    en: "Lost Password",
    it: "Password dimenticata?",
  },
  not_register: {
    en: "Not registered",
    it: "Non registrato",
  },
};

export const afterProceedModalTrans = {
  SELECT_SHIPPING_RECIPIENT: {
    en: "Select Shipping Recipient",
    it: "Seleziona Destinatario Della Spedizione",
  },
  SELECT_RECIPIENT: {
    en: "Select Recipient",
    it: "Seleziona Destinatario",
  },
  CLICK_TO_ADD_NEW_RECIPIENT: {
    en: "Click to add a new recipient",
    it: "Fare clic per aggiungere un nuovo destinatario",
  },
  CONFIRM_TO_SAVE: {
    en: "Confirm and save to logs",
    it: "Confirm E salva Nei Registri",
  },
  SHIPMENT_TYPE: {
    en: "Shipment Type",
    it: "Tipo Della Spedizione",
  },
  DATE: {
    en: "Date",
    it: "Data",
  },
  TOTAL_POINTS: {
    en: "Total Points",
    it: "Totale Punti",
  },
  TOTAL_UN: {
    en: "Total UN",
    it: "Totale UN",
  },
  CANCEL: {
    en: "Cancel",
    it: "Annulla",
  },
};
