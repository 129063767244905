import React, { useState, useEffect } from "react";
import ModalRegister from "../ModalRegister";
import boxLogo from "../../assets/images/consignee.png";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addConsignee } from "../../services/Consignee";
import { useDispatch, useSelector } from "react-redux";
import {
  addConsigneeAction,
  handleAllModals,
} from "../../redux/modalVisibility";
import { toast } from "react-toastify";
import { consignModel } from "../../translations/Modeltranslation";
const ConsigneeModal = ({}) => {
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const consigneeModalState = useSelector(
    (state) => state.modalState.addConsigneeModal
  );
  console.log("Add Modal State ", consigneeModalState);
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: addConsignee,
    onSuccess: (res) => {
      console.log("Submiting Response", res);
      // Invalidate and refetch
      if (res?.data?.success) {
        queryClient.invalidateQueries({
          queryKey: ["consigneeListing"],
        });
        toast.success("Consignee Added Successfuly");
        hideConsigneeModal(true);
        // toast.error("Please Login to Proceed");
        reset();
        setLoading(false);
      } else {
        queryClient.invalidateQueries({
          queryKey: ["consigneeListing"],
        });
        // toast.success(res.data.message);
        setLoading(false);
      }
    },
    onError: (err) => {},
  });

  const hideConsigneeModal = (isSuccess) => {
    // setConsigneeModel(true);
    dispatch(
      handleAllModals({
        addConsigneeModal: { isVisible: false },
        refetchConsignee: isSuccess ? Math.random() : null,
      })
    );
  };
  const lang = useSelector((state) => state.account?.lang);
  const {
    consign_Address_Model,
    consign_Name_Model,
    consign_phone_Model,
    consign_submit_Model,
    Consignee_BUTTON,
    CITY_POSTAL_CODE,
  } = consignModel;

  const onSubmit = (data) => {
    console.log("Consignee Adding Data ", data);
    mutation?.mutate(data);
    setLoading(true);
  };
  return (
    <React.Fragment>
      {consigneeModalState?.isVisible && (
        <ModalRegister>
          <button
            type="button"
            class="text-gray-400 bg-transparent   rounded-lg text-sm w-8 h-8 ml-auto flex justify-end items-center   "
            data-modal-hide="staticModal"
            onClick={hideConsigneeModal}
          >
            <i className="fa fa-times text-xl" />
            <span class="sr-only">Close modal</span>
          </button>
          <div className="flex justify-center">
            <span className="flex flex-col bg-amber-500 bg-opacity-50 rounded-full h-40 w-40 z-1 p-5">
              <img
                src={boxLogo}
                // width={300}
                className="text-center m-auto justify-center"
              />
            </span>
          </div>
          <div>
            <h4 className="m-4 font-bold text-center">
              {Consignee_BUTTON[lang]}
            </h4>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="py-2">
                <input
                  type="text"
                  name="name"
                  id="name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  placeholder={consign_Name_Model[lang]}
                  {...register("name", {
                    required: true,
                  })}
                />
                {errors.name && (
                  <p className="text-red-900 my-1">{errors.name.message}</p>
                )}
              </div>
              <div className="py-2">
                {" "}
                <input
                  type="text"
                  name="city_postal_code"
                  // id="phone_number"
                  placeholder={CITY_POSTAL_CODE[lang]}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  {...register("city_postal_code", {
                    required: true,

                    // minLength: 11,
                  })}
                />
                {errors?.city_postal_code?.type === "required" && (
                  <p className="my-1 text-red-900">Required</p>
                )}
                {/* {errors?.city_postal_code?.type === "minLength" && (
                  <p className="text-red-900 my-1">Min Length should be 11</p>
                )} */}
              </div>
              <div className="py-2">
                <input
                  type="text"
                  name="address"
                  id="Address"
                  placeholder={consign_Address_Model[lang]}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  {...register("address", {
                    required: true,
                  })}
                />
                {errors.address && (
                  <p className="text-red-900 my-1">{errors.address.message}</p>
                )}
              </div>
              <button
                disabled={loading}
                type="submit"
                class="w-full mt-2 bg-[#f89329] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300   rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 font-bold"
              >
                {loading
                  ? `${consign_submit_Model[lang]}`
                  : `${consign_submit_Model[lang]}`}
              </button>
            </form>
          </div>
        </ModalRegister>
      )}
    </React.Fragment>
  );
};

export default ConsigneeModal;
