export const aboutTranslation = {
  ABOUT_DESCRIPTION: {
    en: "With our app you can calculate the 1000 per 1.1.3.6 very quickly, check whether your dangerous goods can be sent with tank/bulk exemption and keep the mandatory register for 5 years, as required by the decree, which can be consulted and downloaded in the App or on the Web.",
    it: "Con la nostra app fai molto velocemente il calcolo dei 1000 per 1.1.3.6, controlli se la tua merce pericolosa puo essere inviata in esenzione Cisterna/Rinfusa e tieni il registro abbligatorio per 5 anni, come previsto dal decreto, consultabile e scaricabile in App o sul web",
  },
  ABOUT_US: {
    en: "about us",
    it: "chi siamo",
  },
};

export const contactTranlation = {
  Contact_us_heading: {
    en: "CONTACT US",
    it: "CONTATTACI",
  },
  Contact_us_text: {
    en: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam explicabo corrupti asperiores commodi . rerum",
    it: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam explicabo corrotti asperiores commodi . rirum",
  },
  Contact_Name: {
    en: "Name",
    it: "nome",
  },
  Contact_us_phone: {
    en: "phone",
    it: "telefono",
  },
  Contact_us_email: {
    en: "Email",
    it: "E-mail",
  },
  Contact_us_address: {
    en: "Address",
    it: "Indirizzo",
  },
  Contact_Message: {
    en: "Message",
    it: "Messaggio",
  },
  Contact_submit: {
    en: "submit",
    it: "invia",
  },
};
export const shipmentCalTranslation = {
  ADD_UN_TO: {
    en: "Add UN(s) to See Calculation",
    it: "Aggiungi UN(s) per visualizzare il calcolo",
  },
  CATEGORY_TRC: {
    en: "Category & TRC",
    it: "Codice Galleria",
  },

  NO_S_DOC_AVAIL: {
    en: "No Shipment Document Available",
    it: "Nessun Documento di Spedizione Disponibile",
  },
  PKG_TYPE: {
    en: "Packaging Type",
    it: "Tipi di imballaggio",
  },
  TOTAL_POINTS: {
    en: "Total Points",
    it: "Totale Punti",
  },
  shipment_heading: {
    en: "SHIPMENT CALCULATION",
    it: "CALCOLO DELLA SPEDIZIONE",
  },
  shipment_subheading: {
    en: "Total Points",
    it: "Totale Punti",
  },
  shipment_allow: {
    en: "ALLOWED",
    it: "PERMESSA",
  },
  shipment_not_allow: {
    en: "Not Allowed",
    it: "Non autorizzato",
  },
  shipment_Categoria: {
    en: "Category",
    it: "Categoria",
  },
  shipment_class: {
    en: "Class",
    it: "Class",
  },
  shipment_label: {
    en: "Label",
    it: "Etichetta",
  },
  shipment_Pg: {
    en: "PG",
    it: "PG",
  },
  shipment_quantity: {
    en: "Quantity",
    it: "Quantità",
  },
  shipment_point: {
    en: "Points",
    it: "punti",
  },
  shipment_payment: {
    en: "Packaging Type",
    it: "Tipo di imballaggio",
  },
  shipment_list: {
    en: "Clear Shipment List",
    it: "Cancella elenco spedizioni",
  },
  shipment_register: {
    en: "Add to Registers",
    it: "Aggiungi ai registri",
  },
  view_detail: {
    en: "View Detail",
    it: "vedi dettagli",
  },
  update_register: {
    en: "Update Register",
    it: "Aggiorna Registri",
  },
};
export const registerTranslation = {
  register_main_heading: {
    en: "Register Detail",
    it: "Dettagli registrazione",
  },
  register_heading: {
    en: "Shippment",
    it: "Spedizione",
  },
  register_no: {
    en: "Shipment No",
    it: "Num delle Spedizione",
  },
  register_date: {
    en: "Date",
    it: "Data",
  },
  register_consignee: {
    en: "Consignee",
    it: "Destinatario",
  },
  register_point: {
    en: "Total Points",
    it: "Totale Punti",
  },
  register_create: {
    en: "Created at ",
    it: "Creato a",
  },

  shipment_allow: {
    en: "ALLOWED",
    it: "PERMESSA",
  },
  delete_btn: {
    en: "Delete Shippment",
    it: "Elimina spedizione",
  },
  edit_btn: {
    en: "Edit Details",
    it: "Modifica i dettagli",
  },
  view_btn: {
    en: "View Details",
    it: "Visualizza dettagli",
  },

  register_view_shippment_type: {
    en: "Shippment Type",
    it: "Tipo di spedizione",
  },
  register_view_shippment_date: {
    en: "Shipment Date",
    it: "Data di spedizione",
  },
  register_view_shippment_consignee: {
    en: "Consignee Details",
    it: "Dettagli del destinatario",
  },
};

export const footerTranslation = {
  footer_download: {
    en: "Download App",
    it: "Scarica l'app",
  },
  footer_contact: {
    en: "Contact Us",
    it: "Contattaci",
  },
  footer_article: {
    en: "Read Articles",
    it: "Leggi gli articoli",
  },
  footer_shop: {
    en: "Online shop",
    it: "Negozio online",
  },
  footer_term_condition: {
    en: "Terms and Conditions",
    it: "Termini e Condizioni",
  },
  footer_reserved: {
    en: "Cargo School and Consulting, All rights reserved.",
    it: "Cargo School e Consulenza, Tutti i diritti riservati.",
  },
};
