import React from "react";
import { useSelector } from "react-redux";

export default function useAlreadyAdded() {
  const localRegisters = useSelector((state) => state.localRegisters);

  const IsAlreadyAdded = (data) => {
    return localRegisters?.some((d) => {
      return d.id == data?.id;
    });
  };

  return { IsAlreadyAdded };
}
