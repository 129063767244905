import React from "react";
import shipType1 from "../../assets/images/box.png";
import shipType2 from "../../assets/images/bulk.jpg";
import { useDispatch, useSelector } from "react-redux";
import { setShipmentType } from "../../redux/account";
import { clearAllRegisters } from "../../redux/localRegisters";
import { homeTranslation } from "../../translations/home";
import { setSelectedTab } from "../../redux/selectedTab";

const Header = () => {
  const dispatch = useDispatch();
  const handleShipmentType = (type) => {
    dispatch(setShipmentType(type));
    dispatch(clearAllRegisters());
  };
  const shipmentType = useSelector((state) => state.account.shipmentType);
  const lang = useSelector((state) => state.account?.lang);
  const selectedTab = useSelector((state) => state.selectedTab);
  const handleSelectedTab = (id) => {
    dispatch(setSelectedTab(id));
  };

  const localRegisters = useSelector((state) => state.localRegisters);

  const { search_bar, shipment, register, bookmark, EXEMPTION, btn_text } =
    homeTranslation;
  return (
    <React.Fragment>
      <div className="container mx-auto">
        <div className="grid sm:grid-cols-2 grid-cols-1 justify-center mt-5 max-w-xl mx-auto gap-5">
          <button
            disabled={shipmentType == "1136"}
            onClick={() => handleShipmentType("1136")}
            className={`border-2 rounded-md relative py-3    font-medium text-sm  flex justify-evenly  items-center ${
              shipmentType == "1136"
                ? "bg-amber-500 border-amber-400"
                : "bg-white border-gray-400"
            }`}
          >
            <img
              src={shipType1}
              alt="box-image"
              className=" rounded-md h-12 w-12"
            />
            <span className="text-left">
              <p className="leading-tight"> {EXEMPTION[lang]}</p>
              <p className="font-bold text-lg leading-tight">ADR 1.1.3.6</p>
              <p className=" text-xs leading-tight">calc.1000 Points</p>
            </span>
            <span className="w-10">
              {/* {shipmentType == "1136" && (
                <i className="fa fa-check text-white rounded-full text-2xl    bg-green-700 p-1 m-0 leading-none" />
              )} */}
            </span>
          </button>
          <button
            disabled={shipmentType == "Bulk"}
            onClick={() => handleShipmentType("Bulk")}
            className={`border-2  rounded-md relative  py-3   font-medium text-sm  flex justify-evenly  items-center  ${
              shipmentType == "Bulk"
                ? "bg-amber-500 border-amber-400"
                : "bg-white border-gray-400"
            } `}
          >
            <img
              src={shipType2}
              alt="box-image"
              className=" rounded-md h-12 "
            />
            <span className="text-left">
              <p className="leading-tight"> {EXEMPTION[lang]}</p>
              <p className="font-bold text-lg leading-tight">
                {btn_text[lang]}
              </p>
              <p className=" text-xs leading-tight">Transport</p>
            </span>
            <span className="w-10">
              {/* {shipmentType == "Bulk" && (
                <i className="fa fa-check text-white rounded-full text-2xl    bg-green-700 p-1 m-0 leading-none" />
              )} */}
            </span>
          </button>
        </div>

        {/* Here defined the tab  */}
        <div className="grid grid-cols-4 gap-4 text-center pt-9 ">
          <div
            className={` border-r-2 border-gray-300 ${
              selectedTab == 1 ? "text-amber-500" : "text-gray-500"
            }`}
            onClick={() => handleSelectedTab(1)}
          >
            <span className="cursor-pointer">
              <i class="fa fa-search   text-5xl" aria-hidden="true"></i>
              <p className="mt-4">{search_bar[lang]}</p>
            </span>
          </div>
          <div
            className={`border-r-2 border-gray-300  ${
              selectedTab == 2 ? "text-amber-500" : "text-gray-500"
            }`}
            onClick={() => handleSelectedTab(2)}
          >
            <span className="cursor-pointer">
              <i class="fa fa-list-alt text-5xl relative" aria-hidden="true">
                {localRegisters?.length > 0 && (
                  <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
                    {localRegisters?.length}
                  </div>
                )}
              </i>
              <p className="mt-4">{shipment[lang]}</p>
            </span>
          </div>
          <div
            className={`border-r-2 border-gray-300  ${
              selectedTab == "3" ? "text-amber-500" : "text-gray-500"
            } `}
            onClick={() => handleSelectedTab(3)}
          >
            <span className="cursor-pointer">
              <i class="fa fa-book text-5xl" aria-hidden="true"></i>
              <p className="mt-4">{register[lang]}</p>
            </span>
          </div>
          <div
            onClick={() => handleSelectedTab(4)}
            className={` ${
              selectedTab == 4 ? "text-amber-500" : "text-gray-500"
            }`}
          >
            <span className="cursor-pointer">
              <i class="fa fa-bookmark text-5xl" aria-hidden="true"></i>
              <p className="mt-4">{bookmark[lang]}</p>
            </span>
          </div>
        </div>
        <hr className="my-10 border-2" />
      </div>
    </React.Fragment>
  );
};

export default Header;
