import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.png";
import logo2 from "../assets/images/iata-iso-widget.png";
import logo3 from "../assets/images/ENAC-ADD_logo.png";
import LoginModal from "../components/Auth/LoginModal";
import { Link, useLocation } from "react-router-dom";
import SignupModal from "../components/Auth/SignModal";
import { navbarMenu } from "../constants/navbar";
import ThemeSwitcher from "../components/switch/ThemeSwitcher";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../redux/account";
import { handleAllModals } from "../redux/modalVisibility";
import { menuTranslation } from "../translations/menu";
import ToggleBtn from "../components/togglebtn";
import VerifyOTPModal from "../components/Auth/VerifyOTP";
import { updateAllBookmark } from "../redux/bookmarks";
import TrialModal from "../components/Auth/TrialModal";
import ExpireModal from "../components/Auth/ExpireModal";
import useSubscription from "../hooks/useCheckSubscription";

export default function Navbar() {
  const { checkSubscription } = useSubscription();
  const { pathname } = useLocation();
  console.log("location ", pathname);
  const lang = useSelector((state) => state?.account?.lang);
  const dispatch = useDispatch();
  const handleLoginModal = () => {
    dispatch(handleAllModals({ loginModal: { isVisible: true } }));
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const loginModalState = useSelector((state) => state?.modalState?.loginModal);
  const trialModalState = useSelector((state) => state?.modalState?.trialModal);
  const expireModalState = useSelector(
    (state) => state?.modalState?.expireModal
  );

  const signupModalState = useSelector(
    (state) => state?.modalState?.signupModal
  );
  const otpModalState = useSelector(
    (state) => state?.modalState?.verifyOTPModal
  );
  const handleLogout = () => {
    dispatch(logoutAction());
    dispatch(updateAllBookmark([]));
  };

  useEffect(() => {
    isAuthenticated && checkSubscription();
  }, []);

  const user = useSelector((state) => state.account.user);
  const isAuthenticated = useSelector((state) => state.account.isAuthenticated);
  const { HOME, CONSIGNEE, ABOUT_US, CONTACT_US, Sign_btn } = menuTranslation;
  return (
    <div className="py-4 text-black shadow-md">
      {loginModalState?.isVisible && <LoginModal />}
      {trialModalState?.isVisible && (
        <TrialModal days={trialModalState?.days} />
      )}
      {expireModalState?.isVisible && (
        <ExpireModal days={expireModalState?.days} />
      )}
      {signupModalState?.isVisible && <SignupModal />}
      {otpModalState?.isVisible && (
        <VerifyOTPModal email={otpModalState?.data} />
      )}
      {/* Navbar Container */}

      <div className="max-w-7xl mx-auto sm:px-10 px-5 flex justify-between items-center">
        {/* Logo */}
        <div className="text-2xl font-bold flex items-center">
          <div className="lg:hidden pe-2 sm:pe-5 lg:pe-0">
            <ToggleBtn />
          </div>
          <Link to="/">
            <img src={logo} className="sm:w-[250px] w-40" />
          </Link>
          <Link to="/" className="pe-2 ps-5 hidden sm:flex ">
            <img
              src={logo3}
              width="70"
              // height={30}
              className="items-center"
              alt="ENAC"
              title="ENAC"
            />
          </Link>
          <Link to="/" className="px-2 items-center hidden sm:block">
            <img src={logo2} width="60" />
          </Link>
        </div>

        {/* Menu Links */}
        <ul className="flex space-x-4 items-center">
          <li className="hidden lg:block">
            <Link
              to="/"
              className={`${
                pathname == "/" ? "text-amber-500" : "text-black"
              } font-medium`}
            >
              {HOME[lang]}
            </Link>
          </li>

          <li className="hidden lg:block">
            <Link
              to="/about"
              className={`${
                pathname == "/about" ? "text-amber-500" : "text-black"
              } hover:text-amber-500 font-medium`}
            >
              {ABOUT_US[lang]}
            </Link>
          </li>

          {isAuthenticated && (
            <li className="hidden lg:block">
              <Link
                to="/consignee"
                className={`${
                  pathname == "/consignee" ? "text-amber-500" : "text-black"
                }  hover:text-amber-500  font-medium`}
              >
                {CONSIGNEE[lang]}
              </Link>
            </li>
          )}
          <li className="hidden lg:block">
            <Link
              to="/contact"
              className={`${
                pathname == "/contact" ? "text-amber-500" : "text-black"
              }  hover:text-amber-500  font-medium`}
            >
              {CONTACT_US[lang]}
            </Link>
          </li>

          {!isAuthenticated && (
            <li>
              <button
                data-modal-target="popup-modal"
                data-modal-toggle="popup-modal"
                type="button"
                className="bg-amber-500 text-white px-4 py-2 rounded uppercase font-medium text-sm"
                onClick={handleLoginModal}
              >
                Login / {Sign_btn[lang]}
              </button>
            </li>
          )}
          <li className="hidden lg:block">
            <ThemeSwitcher />
          </li>

          {isAuthenticated && (
            <li className="relative">
              <div>
                <>
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={toggleDropdown}
                  >
                    <img
                      className="h-8 w-8 flex-none rounded-full bg-gray-50"
                      src={user?.image}
                      alt="image"
                      id="dropdownHoverButton"
                    />
                    <span className="ms-2 text-lg"> {user?.name} </span>
                    <i className="fa fa-chevron-down  ms-1 font-thin text-xs" />
                  </div>

                  <div
                    id="dropdownHover"
                    className={`z-10 absolute ${
                      isDropdownOpen ? "block" : "hidden"
                    } bg-white divide-y divide-gray-100 rounded-lg shadow w-28 dark:bg-gray-700`}
                  >
                    <ul
                      className="py-2 text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="dropdownHoverButton"
                    >
                      <li>
                        <Link
                          to={"/"}
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Profile
                        </Link>
                      </li>
                      <li>
                        <span
                          onClick={handleLogout}
                          className="block px-4 py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                        >
                          Logout
                        </span>
                      </li>
                    </ul>
                  </div>
                </>
              </div>
            </li>
          )}
        </ul>
        {/* mbl responsive */}
      </div>
    </div>
  );
}
