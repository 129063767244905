import React from "react";

export default function useGetMultiplier() {
  const ListIds = [
    "0081",
    "0082",
    "0084",
    "0241",
    "0331",
    "0332",
    "0482",
    "1005",
    "1017",
  ];
  const getMultiplier = (un_number, cat) => {
    // debugger;
    let numb = un_number?.toLowerCase()?.split("un")?.[1];
    let cat_id = cat?.toLowerCase()?.split(" ")?.[0];
    if (
      ListIds?.some((id) => {
        return id == numb;
      })
    ) {
      return 20;
    } else if (cat_id == 1) {
      return 50;
    } else if (cat_id == 2) {
      return 3;
    } else if (cat_id == 3) {
      return 1;
    } else {
      return "unlimited";
    }
  };

  return { getMultiplier };
}
