import { DELETE, GET, PATCH, POST } from "./Adapter/base";

export const addBookmark = (data) => {
  return POST(`bookmarks/store`, data);
};

export const getAllBookmarks = () => {
  return GET(`bookmarks/list`);
};

export const deleteBookmark = (id) => {
  return DELETE(`bookmarks/delete/${id}`);
};
