import { DELETE, GET, PATCH, POST } from "./Adapter/base";

export const getConsigneeList = () => {
  return GET(`consignees/list`);
};

export const addConsignee = (data) => {
  return POST(`consignees/store`, data);
};
export const updateConsignee = (data) => {
  return PATCH(`consignees/update/${data?.id}`, data);
};

export const deleteConsignee = (id) => {
  return DELETE(`consignees/delete/${id}`);
};
