import React from "react";
import ukIcon from "../../assets/images/united-kingdom-uk.svg"; // Replace with your sun icon SVG
import itlyFlag from "../../assets/Icons/it_flag.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../redux/account";
const ThemeSwitcher = () => {
  const language = useSelector((state) => state.account.lang);
  const dispatch = useDispatch();
  const toggleTheme = (lang) => {
    dispatch(setLanguage(lang));
  };
  return (
    <React.Fragment>
      <label
        className="relative inline-flex items-center cursor-pointer"
        onClick={() => toggleTheme(language == "en" ? "it" : "en")}
      >
        <button className=" w-full h-full cursor-pointer">
          <div
            className={`w-16 h-8 bg-gray-200 peer-focus:outline-none rounded-full flex items-center justify-around ${
              language == "en"
                ? "order-1 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:bg-gray-600"
                : "order-2 peer-checked:after:translate-x-0 peer-checked:after:border-gray-300"
            }`}
          >
            <span
              className={` ${
                language == "en"
                  ? "order-1 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:bg-gray-600 uppercase"
                  : "order-2 peer-checked:after:translate-x-0 peer-checked:after:border-gray-300 uppercase"
              }`}
            >
              {language == "en" ? "en" : "it"}
            </span>

            <span
              className={`text-sm ${language == "en" ? "order-2" : "order-1"} `}
            >
              <img
                src={language == "en" ? ukIcon : itlyFlag}
                alt={language == "en" ? "ukIcon" : "italy"}
                className="w-5 h-5 inline-block"
              />
            </span>
          </div>
        </button>
      </label>
    </React.Fragment>
  );
};

export default ThemeSwitcher;
