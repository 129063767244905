import { GET } from "./Adapter/base";

export const getAllChemicals = (data) => {
  return GET(`chemicals/list?${data}`);
};

/**Api reposne  */
// getAllChemicals(data).then(res=>{
//   console.log();
// })
// .catch(err=>{

// })
