import React, { useEffect, useState } from "react";
import boxLogo from "../../../assets/images/box.png";
import bulkLogo from "../../../assets/images/bulk.jpg";
import { registerMenu } from "../../../constants/register";
import ListRegisterDelete from "../../../components/Registers/DeleteRegisterModal";
import { getRegisterInvoice } from "../../../services/Registers";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../../common/Loader";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleAllModals } from "../../../redux/modalVisibility";
import moment from "moment";
import { toast } from "react-toastify";
import {
  registerTranslation,
  shipmentCalTranslation,
} from "../../../translations/about";
import { CalendarIcon } from "../../../assets/Icons/icons";
export default function RegistersTab() {
  const [deleteRegister, setdeleteRegister] = useState(false);
  const [registersData, setRegistersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bulk, setBulk] = useState([]);
  const [nonBulk, setNonBulk] = useState([]);

  const dispatch = useDispatch();
  const deleteShipmentModalState = useSelector(
    (state) => state?.modalState?.deleteShipmentModal
  );
  const isRefetch = useSelector((state) => state?.modalState?.refetchShipment);
  const isAuthenticated = useSelector((state) => state.account.isAuthenticated);
  const shipmentType = useSelector((state) => state.account.shipmentType);

  // const {
  //   isLoading: registersLoading,
  //   error: registersError,
  //   data: registers,
  // } = useQuery({
  //   queryKey: ["registersListing"],
  //   queryFn: getRegisterInvoice,
  // });
  const fetchData = () => {
    getRegisterInvoice().then((res) => {
      if (res?.data?.success) {
        console.log("REs From Regiters ", res?.data?.data);

        let tempBulk = res?.data?.data?.filter(
          (d) => d?.shipment_type != "1136"
        );
        let tempNonBulk = res?.data?.data?.filter(
          (d) => d?.shipment_type == "1136"
        );

        setBulk(tempBulk);
        setNonBulk(tempNonBulk);
        setRegistersData(res?.data?.data);
        dispatch(
          handleAllModals({
            isLoading: false,
          })
        );
      } else {
      }
    });
  };
  const openLoginModal = () => {
    dispatch(handleAllModals({ loginModal: { isVisible: true } }));
  };
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(
        handleAllModals({
          isLoading: true,
        })
      );
      fetchData();
    } else {
      openLoginModal();

      toast.info("Please Login to Proceed");
    }
  }, [isAuthenticated]);

  // console.log("Registers ", registers);
  // let registersData = registers?.data?.data || [];
  const handleDelete = (id) => {
    dispatch(
      handleAllModals({ deleteShipmentModal: { isVisible: true, id: id } })
    );

    // setdeleteRegister(true);
  };

  const lang = useSelector((state) => state.account?.lang);
  const {
    register_heading,
    delete_btn,
    edit_btn,
    register_consignee,
    register_create,
    register_date,
    register_no,
    register_point,
    shipment_allow,

    view_btn,
  } = registerTranslation;

  const { shipment_not_allow } = shipmentCalTranslation;
  return (
    <React.Fragment>
      {deleteShipmentModalState?.isVisible && (
        <ListRegisterDelete id={deleteShipmentModalState?.id} />
      )}
      <div className="py-10 container mx-auto">
        <div className="max-w-4xl mx-auto ">
          <h1 className="font-bold text-3xl mb-8">{register_heading[lang]}</h1>
          {(shipmentType == "1136" ? nonBulk : bulk).map((item) => {
            const {
              consignee,
              total_points,
              date,
              id,
              record_number,
              adr,
              created_at,
              user,
              shipment_type,
              invoice_date,
              invoice_items,
            } = item;
            return (
              <div key={id}>
                <div
                  className="border bg-white mx-auto  my-5 rounded-2xl"
                  key={id}
                >
                  <div className="flex text-sm p-6">
                    <div className="w-full">
                      <div className="grid grid-cols-2">
                        <div className="flex flex-col gap-4">
                          <div className="flex gap-7 items-center">
                            <div className=" bg-gray-200 w-16 h-16 p-3 rounded-full">
                              <span className="">
                                <CalendarIcon fill="gray" />
                              </span>
                            </div>
                            <div>
                              <div className=" text-gray-400">
                                {register_date[lang]}
                              </div>
                              <div className="text-lg text-gray-700 font-bold">
                                {moment(created_at).utc().format("DD MMM YYYY")}
                              </div>
                            </div>
                          </div>
                          <div className="flex gap-7 items-center">
                            <div
                              className={`w-16 h-16 flex items-center rounded-full p-3 ${
                                shipment_type == 1136
                                  ? "bg-[#ffd7d577]"
                                  : "bg-[#4fe8ff44]"
                              }`}
                            >
                              <img
                                src={shipment_type == 1136 ? boxLogo : bulkLogo}
                                // width={80}
                                className="mx-auto w-full"
                              />
                            </div>
                            <div className=" flex items-center">
                              <div>
                                <div
                                  className={`px-4 py-2 rounded-full uppercase text-sm font-bold  ${
                                    shipment_type == 1136
                                      ? "bg-[#ffd7d577] text-amber-500"
                                      : "bg-[#4fe8ff44] text-blue-600"
                                  }`}
                                >
                                  {shipment_type == 1136
                                    ? "ADR 1.1.3.6"
                                    : "Bulk/Tank"}
                                </div>
                                <div className="font-bold text-lg text-gray-700">
                                  {invoice_items?.length} UN Inclusi
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col gap-2 justify-between items-end">
                          <div className="border-r-1 text-end">
                            <div className="font-bold text-gray-400">
                              {register_no[lang]}
                            </div>
                            <div className="  text-gray-700 font-bold text-lg">
                              {record_number}
                            </div>
                          </div>
                          <div className=" text-end">
                            {shipment_type == 1136 && (
                              <>
                                <div className="font-bold text-gray-400">
                                  {register_point[lang]}
                                </div>
                                <div
                                  className={`text-lg font-bold  ${
                                    total_points > 1000
                                      ? "text-red-600"
                                      : "text-green-600"
                                  } `}
                                >
                                  {total_points}
                                  {/* (
                                  {total_points > 1000
                                    ? shipment_not_allow[lang]
                                    : shipment_allow[lang]}
                                  ) */}
                                </div>
                              </>
                            )}
                          </div>
                          <div className="flex justify-end">
                            <Link
                              to={`/edit_registers/${id}`}
                              className="bg-[#4FE7FF] text-[#002667]  mx-1 h-10 w-10 rounded-full text-center flex items-center"
                            >
                              <i className="fa fa-pencil mx-auto text-xl"></i>
                              {/* {edit_btn[lang]} */}
                            </Link>
                            <button
                              className="bg-[#FFD7D5] text-red-600 mx-1 w-10 h-10 rounded-full text-center flex items-center"
                              onClick={() => handleDelete(id)}
                            >
                              <i className="fa fa-trash text-red-600 mx-auto text-xl"></i>{" "}
                              {/* {delete_btn[lang]} */}
                            </button>
                            <Link
                              to={`/view_registers/${id}`}
                              state={{ shipment_no: record_number }}
                              className="bg-amber-500 ms-1 px-3 font-bold h-10 flex items-center rounded-full text-center text-white"
                            >
                              {/* <i class="fa fa-eye"></i>  */}
                              {view_btn[lang]}
                            </Link>
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
}
