import React from "react";
import playIcon from "../assets/Icons/playIcon.png";
import bannerImg from "../assets/images/banner_img.webp";
import { useSelector } from "react-redux";
import { downloadTranslation } from "../translations/downloadSection";
import { Link } from "react-router-dom";
export default function DownloadSection() {
  const lang = useSelector((state) => state?.account?.lang);
  const { DOWN_DESCRIPTION, HEADING } = downloadTranslation;
  return (
    <React.Fragment>
      <div class=" py-5 bg-slate-200">
        <div className="container px-10 mx-auto">
          <div class="grid md:grid-cols-2 grid-cols-1 gap-4 items-center">
            <div>
              <p className="uppercase text-lg text-themeColor">
                App Store / google play
              </p>
              <h1 className="font-bold text-4xl mb-8 capitalize">
                {HEADING[lang]}
              </h1>
              {/* <p>{DOWN_DESCRIPTION[lang]}</p> */}
              <div className="flex py-4">
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://apps.apple.com/us/app/adr-cargo-school-dang-goods/id6469696566"
                  className="border mr-1 border-gray-300 bg-white hover:bg-[#f3f7f8] flex p-3 rounded items-center"
                >
                  <div className="pr-3 pl-1">
                    <i className="fa fa-apple text-2xl" />
                  </div>
                  <div className="text-left font-medium">
                    <p className="text-xs leading-tight text-gray-600">
                      Download on the
                    </p>
                    <p className="text-lg leading-tight">App Store</p>
                  </div>
                </Link>

                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="https://play.google.com/store/apps/details?id=com.adrapp.cargoschool"
                  className="border border-gray-300 bg-white hover:bg-[#f3f7f8] flex p-3 rounded items-center"
                >
                  <div className="pr-3 pl-1">
                    <img src={playIcon} width="30" height="30" />
                  </div>
                  <div className="text-left font-medium">
                    <p className="text-xs leading-tight text-gray-600">
                      Download on the
                    </p>
                    <p className="text-lg leading-tight">Google Play</p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="flex md:justify-end justify-center">
              <img src={bannerImg} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
