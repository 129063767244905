import React, { useEffect, useLayoutEffect, useState } from "react";
import { getAllChemicals } from "../../services/Chemicals";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { homeTranslation } from "../../translations/home";
import Header from "../../components/Home/headerome";
import SearchTab from "./Tabs/SearchTab";
import CalculationTab from "./Tabs/CalculationTab";
import RegistersTab from "./Tabs/RegistersTab";
import UpperHeader from "../../components/Home/upperheader";
import BookmarkTab from "./Tabs/BookmarkTab";
import { getAllBookmarks } from "../../services/Bookmarks";
import { updateAllBookmark } from "../../redux/bookmarks";
export default function Home() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [searchType, setSearchType] = useState("un_number");
  const [searchData, setSearchData] = useState({ searchValue: "0004" });
  // const [selectedTab, setSelectedTab] = useState(1);
  const localRegisters = useSelector((state) => state.localRegisters);
  const selectedTab = useSelector((state) => state.selectedTab);
  const isAuthenticated = useSelector((state) => state.account.isAuthenticated);
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.account?.lang);
  const { NAME_EN } = homeTranslation;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    isLoading: bookmarksLoading,
    error: bookmarksError,
    data: bookmarks,
  } = useQuery({
    queryKey: ["bookmarksListing"],
    queryFn: getAllBookmarks,
    enabled: isAuthenticated,
  });

  const bookmarksData = bookmarks?.data?.data;

  useEffect(() => {
    if (bookmarksData?.length > 0) {
      let resMark = bookmarksData?.map((d) => {
        return { ...d?.chemical, bookmark_id: d?.id };
      });
      console.log("resMark ", resMark);
      dispatch(updateAllBookmark(resMark));
    }
  }, [bookmarksData]);

  console.log("Bookmarks Listing ", bookmarksData);

  return (
    <React.Fragment>
      <UpperHeader />
      <Header />

      {selectedTab == 1 && <SearchTab />}

      {selectedTab == 2 && <CalculationTab />}
      {selectedTab == 3 && <RegistersTab />}
      {selectedTab == 4 && <BookmarkTab />}
    </React.Fragment>
  );
}
