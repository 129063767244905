import React, { useState } from "react";
import boxLogo from "../assets/images/box.png";
import bulkLogo from "../assets/images/bulk.jpg";
// import AddSubstanceModal from "./SearchTab/AddSubstanceModal";
import { useDispatch, useSelector } from "react-redux";
import { handleAllModals } from "../redux/modalVisibility";
import { toast } from "react-toastify";
import AddSubstanceModal from "./SearchTab/AddSubstanceModal";
import useIsBulk from "../hooks/useIsBulk";
import useAlreadyAdded from "../hooks/useAlreadyAdded";
import useAlreadyBookmarked from "../hooks/useAlreadyBookmarked";
import {
  addBookmark,
  removeBookmark,
  updateAllBookmark,
} from "../redux/bookmarks";
import { shipmentCalTranslation } from "../translations/about";
import { toastNotification } from "../translations/Modeltranslation";
import {
  deleteBookmark as deleteBookmarkApi,
  addBookmark as addBookmarkApi,
} from "../services/Bookmarks";

export default function ListCard({ data }) {
  console.log("Data from Search Card ", data);
  const { IsAlreadyAdded } = useAlreadyAdded();
  const { IsAlreadyMarked } = useAlreadyBookmarked();
  const bookmarks = useSelector((state) => state.bookmarks);

  const dispatch = useDispatch();
  const lang = useSelector((state) => state.account?.lang);
  const {
    shipment_Categoria,
    shipment_class,
    shipment_Pg,
    shipment_label,
    view_detail,
    CATEGORY_TRC,
  } = shipmentCalTranslation;
  const shipmentType = useSelector((state) => state.account.shipmentType);
  const { listCard_infoToast, listCard_toast } = toastNotification;
  const localRegisters = useSelector((state) => state.localRegisters);
  const { getIsBulk } = useIsBulk();
  const handleAddSubstanceModal = () => {
    if (IsAlreadyAdded(data)) {
      toast.error(`${listCard_toast[lang]}`);
    } else {
      if (shipmentType == "1136") {
        dispatch(
          handleAllModals({
            addSubstanceModal: { isVisible: true, data: data },
          })
        );
      } else {
        if (getIsBulk(data)) {
          dispatch(
            handleAllModals({
              addSubstanceModal: { isVisible: true, data: data },
            })
          );
        } else {
          toast.info(`${listCard_infoToast[lang]}`);
        }
      }
    }
  };

  const handleBookMark = () => {
    if (IsAlreadyMarked(data)) {
      console.log("Data ", data);
      dispatch(removeBookmark(data?.id));
      deleteBookmarkApi(
        bookmarks?.find((d) => d?.id == data?.id)?.bookmark_id
      ).then((res) => {
        console.log("Delete Bookmark Res ", res);
        let resMark =
          res?.data?.data &&
          res?.data?.data?.map((d) => {
            return { ...d?.chemical, bookmark_id: d?.id };
          });
        console.log("resMark ", resMark);
        resMark && dispatch(updateAllBookmark(resMark));
      });
    } else {
      dispatch(addBookmark(data));
      addBookmarkApi({ chemical_id: data?.id }).then((res) => {
        console.log("Bookmark Res ", res);
        let resMark = res?.data?.data?.map((d) => {
          return { ...d?.chemical, bookmark_id: d?.id };
        });
        console.log("resMark ", resMark);
        dispatch(updateAllBookmark(resMark || []));
      });
    }
  };

  const substanceModalState = useSelector(
    (state) => state.modalState.addSubstanceModal
  );

  return (
    <React.Fragment>
      <div className="border bg-white rounded-lg mx-auto shadow-md my-5">
        {substanceModalState?.isVisible && <AddSubstanceModal />}
        <div className="flex text-sm p-4">
          <div className="w-4/5">
            <div className="flex">
              <span className="flex bg-white rounded-full w-12 h-11 z-1">
                <img
                  src={shipmentType == "1136" ? boxLogo : bulkLogo}
                  width={40}
                  className="text-center m-auto justify-center"
                />
              </span>
              <span className="bg-[#f89329] flex uppercase text-sm font-bold py-1 pl-7 pr-4 h-8 rounded-r-full -ml-5 my-auto">
                <span className="my-auto"> {data?.un_number} </span>
              </span>
              <div className=" grid grid-cols-4 text-sm ml-5">
                <div className="border-r-1 text-start px-4 border-r">
                  <div className="font-bold">{shipment_Categoria[lang]}</div>
                  <div className="text-xs text-gray-700">
                    {data?.trc_transport_category?.split(" ") &&
                      data?.trc_transport_category?.split(" ")[0]}{" "}
                  </div>
                </div>
                <div className="px-4 border-r text-start">
                  <div className="font-bold">{shipment_class[lang]}</div>
                  <div className="text-xs text-gray-700">{data?.class}</div>
                </div>
                <div className="border-r text-start px-4">
                  <div className="font-bold">{shipment_label[lang]}</div>
                  <div className="text-xs text-gray-700"> {data?.label} </div>
                </div>
                <div className="text-start px-4">
                  <div className="font-bold">{shipment_Pg[lang]}</div>
                  <div className="text-xs text-gray-700">
                    {data?.packing_group}{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-3">
              {lang == "en" ? data?.name_en : data?.name_it}
            </div>
          </div>
          <div className="w-1/5 flex justify-end">
            <span>
              <div className="flex justify-center mb-2">
                <button className="mx-1 flex" onClick={handleBookMark}>
                  <i
                    className={`fa fa-bookmark shadow-md  w-8 h-8 rounded-full flex items-center justify-center ${
                      IsAlreadyMarked(data)
                        ? "text-white bg-green-600"
                        : "text-gray-500 bg-gray-600 bg-opacity-30"
                    } `}
                  />
                </button>
                <button className="mx-1" onClick={handleAddSubstanceModal}>
                  {IsAlreadyAdded(data) ? (
                    <i className="fa fa-check shadow-md text-white w-8 p-2 h-8 rounded-full bg-green-600  flex items-center justify-center" />
                  ) : (
                    <i className="fa fa-plus shadow-md text-gray-500 w-8 p-2 h-8 rounded-full bg-gray-600 bg-opacity-30 flex items-center justify-center" />
                  )}{" "}
                </button>
              </div>
              <div className="flex justify-center">
                <button className="px-3 py-2 bg-amber-500 text-white rounded text-xs">
                  {view_detail[lang]}
                </button>
              </div>
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
