import React, { useLayoutEffect } from "react";
import Navbar from "../Layout/Navbar";
import Footer from "../Layout/Footer";
import DownloadSection from "../components/DownloadSection";
import HeroSection from "../components/HeroSection";
import { contactTranlation } from "../translations/about";
import { useSelector } from "react-redux";

export default function Contact() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const lang = useSelector((state) => state.account?.lang);
  const {
    Contact_Message,
    Contact_Name,
    Contact_us_address,
    Contact_us_email,
    Contact_us_heading,
    Contact_us_phone,
    Contact_us_text,
    Contact_submit,
  } = contactTranlation;
  return (
    <div>
      <HeroSection
        title={Contact_us_heading[lang]}
        description={Contact_us_text[lang]}
      />
      <div className="container mx-auto px-10 py-16">
        <div className=" grid grid-cols-2 text-sm">
          <div className="p-8">
            <div className="mb-6">
              <h2 className=" uppercase text-2xl font-bold">Cargo School And Consulting srl</h2>
            </div>
            <div>
              <h3 className="text-lg font-medium capitalize">
                {Contact_us_phone[lang]} :
              </h3>
              <p>0039-06-79350339</p>
            </div>
            <div>
              <h3 className="text-lg font-medium">
                {Contact_us_email[lang]} :
              </h3>
              <p>supportoelearning@cargoschool.com</p>
            </div>
            <div>
              <h3 className="text-lg font-medium">
                {Contact_us_address[lang]}:
              </h3>
              <p>Via Nicolo Porpora, 12 00198 Roma</p>
            </div>
            {/* <div className="py-4">
              <iframe
                class="gmap_iframe"
                width="100%"
                height="400px"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=2880 Broadway, New York&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div> */}
          </div>
          <div class="w-full mx-auto bg-white p-8 rounded ">
            <h1 class="text-2xl font-bold mb-6 uppercase">
              {Contact_us_heading[lang]}
            </h1>
            <form>
              <div class="mb-4">
                <label
                  class="block text-sm font-medium text-gray-600 mb-2"
                  for="name"
                >
                  {Contact_Name[lang]}
                </label>
                <input
                  class="w-full px-3 py-2 border rounded focus:outline-none focus:border-indigo-500"
                  type="text"
                  id="name"
                  name="name"
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  class="block text-sm font-medium text-gray-600 mb-2"
                  for="email"
                >
                  {Contact_us_email[lang]}
                </label>
                <input
                  class="w-full px-3 py-2 border rounded focus:outline-none focus:border-indigo-500"
                  type="email"
                  id="email"
                  name="email"
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  class="block text-sm font-medium text-gray-600 mb-2"
                  for="phone"
                >
                  {Contact_us_phone[lang]}
                </label>
                <input
                  class="w-full px-3 py-2 border rounded focus:outline-none focus:border-indigo-500"
                  type="phone"
                  id="phone"
                  name="phone"
                  required
                />
              </div>

              <div class="mb-4">
                <label
                  class="block text-sm font-medium text-gray-600 mb-2"
                  for="message"
                >
                  {Contact_Message[lang]}
                </label>
                <textarea
                  class="w-full px-3 py-2 border rounded focus:outline-none focus:border-indigo-500"
                  id="message"
                  name="message"
                  rows="4"
                  required
                ></textarea>
              </div>

              <button
                type="submit"
                class="px-6 py-2 bg-[#f89329] text-white rounded hover:bg-indigo-600 focus:outline-none focus:bg-indigo-700"
              >
                {Contact_submit[lang]}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
